import { APP_SHARE_CALENDER_LIST_URL } from '../constants';
import { mockedCalendarShareList } from '../mocks';
import { CalendarResponse, CalendarShareInfo } from '../types';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.AppShare.CalendarShareList';

export async function getCalenderShareList(
  companyGroupId: string | number,
): Promise<CalendarShareInfo[]> {
  try {
    return new Promise((resolve) => {
      setTimeout(() => {
        const resData =
          mockedCalendarShareList as CalendarResponse<CalendarShareInfo>;
        resolve(resData.data);
      }, 200);
    });

    // const response = await fetch(APP_SHARE_CALENDER_LIST_URL(companyGroupId), {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'X-Referer-Info': window.location.host,
    //   },
    // });

    // if (!response.ok) {
    //   throw new Error('getCalenderShareList 오류 발생.');
    // }

    // const resData =
    //   (await response.json()) as CalendarResponse<CalendarShareInfo>;

    // return resData.data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
