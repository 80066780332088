import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@dop-ui/react/shared/ui/button';
import { CalendarIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CloudDownloadIcon, TrashCanIcon } from '@dop-ui/icons/react/dop/24';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { HolidayType, ScheduleInfo, ScheduleType } from './types';
import { ScheduleTypeChip } from './components/schedule-type-chip';

export function CompanyScheduleManagement() {
  const [searchingYear, setSearchingYear] = useState<number>(
    new Date().getFullYear(),
  );

  const columnDefs: ColumnDef<ScheduleInfo>[] = [
    {
      id: 'year',
      accessorKey: 'year',
      header: '연도',
    },
    {
      id: 'date',
      accessorFn: (info) => {
        const { startDate, endDate } = info;

        return endDate ? `${startDate} ~ ${endDate}` : startDate;
      },
      header: '날짜',
    },
    {
      id: 'scheduleType',
      accessorFn: (info) => ({
        scheduleType: info.scheduleType,
        holidayType: info.holidayType,
      }),
      header: '일정 구분',
      cell: (info) => {
        const { scheduleType, holidayType } = info.getValue() as {
          scheduleType: ScheduleType;
          holidayType: HolidayType;
        };

        return (
          <ScheduleTypeChip
            scheduleType={scheduleType}
            holidayType={holidayType}
          />
        );
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: '일정명',
    },
    {
      id: 'isPaid',
      accessorFn: (info) => (info.holidayPayType === 'PAID' ? '유급' : '무급'),
      header: '유/무급 여부',
    },
  ];

  return (
    <div className="size-full px-[72px] pb-[40px] pt-[24px]">
      <div className="p-[40px] border border-solid border-[#D8D8D8] //border-[--color-border-level3] rounded-[12px]">
        <p className="py-[4px] text-[#363636] //text-[--color-text-level1] text-[20px] font-[600] leading-[30px]">
          전사 일정
        </p>
        <div className="flex flex-wrap items-center w-full mt-[24px] gap-[8px]">
          <Button
            className="h-[40px] px-[16px] border border-solid border-[#D8D8D8] //border-[--color-border-field] rounded-[8px]"
            autosize="auto"
          >
            <CalendarIcon className="size-[20px]" />
            <p className="ml-[4px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              {searchingYear}
            </p>
          </Button>
          <div className="flex-grow" />
          <Button className="h-[40px] px-[4px]" autosize="auto">
            <PlusIcon className="size-[24px]" />
            <p className="ml-[4px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              일정 등록
            </p>
          </Button>
          <Button className="h-[40px] px-[4px]" autosize="auto">
            <TrashCanIcon className="size-[24px]" />
            <p className="ml-[4px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              일정 삭제
            </p>
          </Button>
          <div className="w-[1px] h-[12px] flex-shrink-0 bg-[#D8D8D8]" />
          <Button className="ml-[4px] h-[40px] px-[4px]" autosize="auto">
            <CloudDownloadIcon className="size-[24px]" />
            <p className="ml-[4px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
              휴일 가져오기
            </p>
          </Button>
        </div>
        <TablePrimitives.Root<ScheduleInfo>
          className="mt-[16px]"
          contents={[
            {
              id: 1,
              name: '신정',
              scheduleType: 'HOLIDAY',
              dateType: 'DATE_SPECIFICATION',
              startDate: '2022-01-01',
              endDate: '2022-01-01',
              year: 2022,
              month: 1,
              sequence: 1,
              dayOfWeek: 'SATURDAY',
              calendarRepeatType: 'NONE',
              holidayType: 'STATUTORY_HOLIDAY',
              isSubstituteHoliday: false,
              holidayPayType: 'PAID',
              wageRate: 1,
              calendarType: 'USER',
            },
            {
              id: 2,
              name: '설날',
              scheduleType: 'HOLIDAY',
              dateType: 'DATE_SPECIFICATION',
              startDate: '2022-02-01',
              endDate: '2022-02-01',
              year: 2022,
              month: 2,
              sequence: 1,
              dayOfWeek: 'SATURDAY',
              calendarRepeatType: 'NONE',
              holidayType: 'CONTRACT_HOLIDAY',
              isSubstituteHoliday: false,
              holidayPayType: 'PAID',
              wageRate: 1,
              calendarType: 'USER',
            },
          ]}
          columnDefs={columnDefs}
          selectable
        >
          <TablePrimitives.Contents<ScheduleInfo>
            enableRowSelection={(row) =>
              !(
                row.original.scheduleType === 'HOLIDAY' &&
                row.original.holidayType === 'STATUTORY_HOLIDAY'
              )
            }
          ></TablePrimitives.Contents>
        </TablePrimitives.Root>
      </div>
    </div>
  );
}
