import { fetchGraphQL } from '@dop-ui/react/shared/lib/fetch/client';
import { gql } from 'graphql-request';

export const QUERY_KEY = 'companyGroupIdQuery';

interface CompanyGroupIdResponse {
  data: { adminCompany: { companyGroupId: number } };
}

export const Query = gql`
  query ${QUERY_KEY} {
    adminCompany {
      companyGroupId
    }
  }
`;

export async function getCompanyGroupId() {
  try {
    const response = await fetchGraphQL({ query: Query });
    const { data } = (await response.json()) as CompanyGroupIdResponse;

    return data.adminCompany.companyGroupId;
  } catch (error) {
    console.error(error);
  }
}
