import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { AppChildInfo, ScopeType, ShareNodeInfo } from '../../types';
import { Field } from '../field';
import { useValuesContext } from '../provider';
import { ClassSiteConfig } from './class-site-config';
import { OwnerList } from './owner-list';

export interface Props<T extends AppChildInfo> {
  withPermission?: boolean;
  shares?: ShareNodeInfo[];
  appChildList?: T[];
  defaultAppId?: number | null;
  onChange?: (appChild: T) => void;
  onChangeShares?: (newShares: ShareNodeInfo[]) => void;
}

/**
 * @descritpion 공유설정 영역을 설정 컴포넌트로, 개발자는 ShareConfigDialog의 children으로 해당 컴포넌트를 받아들여 개발 가능합니다.
 */
export function ShareAreaConfig<T extends AppChildInfo>({
  withPermission = true,
  shares = [],
  appChildList,
  defaultAppId,
  onChange,
  onChangeShares,
}: Props<T>) {
  const { t } = useTranslation('component');
  // 공개 범위가 ALL인 경우, ClassType은 무조건 COMPANY에 해당합니다.
  const [scope, setScope] = useState<ScopeType>('ALL');
  const { selectedAppCode, selectedCompany, companyList } = useValuesContext();

  // 사이트 변경 시 첫번째 app 선택
  useEffect(() => {
    onChange &&
      onChange({
        id: appChildList?.[0]?.id,
        name: appChildList?.[0]?.name,
      } as T);
  }, [appChildList]);

  useEffect(() => {
    if (defaultAppId) {
      const selectedApp = appChildList?.find((app) => app.id === defaultAppId);
      onChange && selectedApp && onChange(selectedApp);
    }
  }, [defaultAppId]);

  const replaceContents = (title: string) => {
    if (selectedAppCode === 'dop-board') {
      return title.replace(
        '{appName}',
        t('globalconfig.multiCompanyManagement.appShare.board.title'),
      );
    } else if (selectedAppCode === 'dop-asset') {
      return title.replace(
        '{appName}',
        t('globalconfig.multiCompanyManagement.appShare.asset.title'),
      );
    } else if (selectedAppCode === 'dop-calendar') {
      return title.replace(
        '{appName}',
        t('globalconfig.multiCompanyManagement.appShare.calendar.title'),
      );
    } else {
      return title.replace(
        '{appName}',
        t('globalconfig.multiCompanyManagement.appShare.approval.title'),
      );
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedApp = appChildList?.find(
      (app) => app.id === Number(e.target.value),
    );
    if (e.target.value !== 'no-data' && selectedApp) {
      onChange && onChange(selectedApp);
    }
  };

  return (
    <>
      <Field
        title={replaceContents(
          t(
            'globalconfig.multiCompanyManagement.appShare.dialog.field.selectApp',
          ),
        )}
      >
        <select
          className={clsx(
            'grow px-3 h-[40px] text-[14px] text-[#363636] font-normal rounded-[8px] border border-solid border-[#D8D8D8]',
            {
              'bg-[#F2F2F2] border-none text-[#808080]':
                !appChildList || appChildList?.length === 0,
            },
          )}
          defaultValue={defaultAppId ?? appChildList?.[0]?.id}
          onChange={onChangeHandler}
        >
          {!appChildList || appChildList?.length === 0 ? (
            <option key="no-data" value="no-data">
              {replaceContents(
                t(
                  'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.noAppChildrenData',
                ),
              )}
            </option>
          ) : (
            appChildList?.map((appInfo) => (
              <option key={appInfo.id} value={appInfo.id}>
                {appInfo.name}
              </option>
            ))
          )}
        </select>
      </Field>
      <Field
        title={t(
          'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig',
        )}
      >
        <div className="grow flex flex-col">
          <div className="w-full gap-[24px] flex">
            <label className="flex items-center">
              <input
                type="radio"
                value={scope}
                checked={scope === 'ALL'}
                onChange={(e) => setScope(e.target.checked ? 'ALL' : 'PART')}
              />
              <span className="ms-2 font-normal text-[14px] leading-[21px] -tracking-[0.28px] text-[#363636]">
                {t(
                  'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.all',
                )}
              </span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value={scope}
                checked={scope === 'PART'}
                onChange={(e) => setScope(e.target.checked ? 'PART' : 'ALL')}
              />
              <span className="ms-2 font-normal text-[14px] leading-[21px] -tracking-[0.28px] text-[#363636]">
                {t(
                  'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.part',
                )}
              </span>
            </label>
          </div>
          <ClassSiteConfig
            defaultPermitState={withPermission}
            scopeType={scope}
            companyList={companyList.filter(
              (company) => company.companyId !== selectedCompany?.companyId,
            )}
            onClickAddButton={(newShare) => {
              if (!shares.find((share) => share.nodeId === newShare.nodeId)) {
                onChangeShares && onChangeShares([...shares, newShare]);
              }
            }}
          />
          <OwnerList
            shares={shares.sort() ?? []}
            onChangeShares={onChangeShares}
          />
        </div>
      </Field>
    </>
  );
}

export default ShareAreaConfig;
