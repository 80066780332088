import { useState } from 'react';
import { clsx } from 'clsx';
import { useSuspenseQuery } from '@tanstack/react-query';
import * as getMenuListQueries from '../apis/get-menu-list';

import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';

import { Button } from '../../../shared/ui/button';
import InfinityNotificationList from '../infinity-list';
import styles from './noti-list.module.css';
import * as Dialog from '../../../shared/ui/dialog';

import useNotificationMutations from '../hooks/use-notification-mutations';
import { CheckIcon } from '@dop-ui/icons/react/dop/16';
import {
  DeleteIcon,
  SettingsIcon,
  ChevronDownIcon,
} from '@dop-ui/icons/react/dop/24';

interface ToggleGroup {
  groupName: string;
  open: boolean;
}

interface Props {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}
function NotificationList({ open, onOpenChange }: Props) {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState<string>('all');
  const [menuTitle, setMenuTitle] = useState(t('notification.allNotification'));
  const { deleteAll, readAll } = useNotificationMutations();

  const handleOnChangeMenu = (appCode: string, appName: string) => {
    if (appCode === selectedMenu) return;
    setMenuTitle(appName);
    setSelectedMenu(appCode);
  };

  const data = useSuspenseQuery({
    queryKey: [getMenuListQueries.QUERY_KEY],
    queryFn: async () => getMenuListQueries.getMenuList(),
  });

  const elements = data.data.elements ?? [];

  const generateGroup = () => {
    return elements.reduce<ToggleGroup[]>((acc, current) => {
      const groupFound = acc.find(
        (group) => group.groupName === current.portalType,
      );
      if (!groupFound) {
        acc.push({ groupName: current.portalType, open: false });
      }
      return acc;
    }, []);
  };

  const [groupList, setGroupList] = useState<ToggleGroup[]>([
    ...generateGroup(),
  ]);

  const isGroupOpened = (name: string) => {
    const found = groupList.find((group) => group.groupName === name);
    return found?.open;
  };

  const handleGroupToggle = (name: string) => {
    setGroupList((prevGroupList) => {
      return prevGroupList.map((group) => {
        const { groupName } = group;
        if (groupName === name) {
          return {
            groupName,
            open: !group.open,
          };
        }
        return group;
      });
    });
  };

  const handleOnDeleteAll = async () => {
    await deleteAll();
  };

  const handleOnReadAll = () => {
    readAll();
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content usePortal className="w-full h-full">
        <div className={styles.Wrapper}>
          <div className={styles.NotificationMenu}>
            <p className={styles.Title}>{t('notification.title')}</p>
            <div className={styles.MenuList}>
              <button
                className={
                  selectedMenu === 'all' ? styles.SelectedMenu : styles.Menu
                }
                onClick={() =>
                  handleOnChangeMenu('all', t('notification.allNotification'))
                }
              >
                {t('notification.allNotification')}
              </button>

              <button
                onClick={() =>
                  handleOnChangeMenu(
                    'unread',
                    t('notification.unReadNotification'),
                  )
                }
                className={
                  selectedMenu === 'unread' ? styles.SelectedMenu : styles.Menu
                }
              >
                {t('notification.unReadNotification')}
              </button>

              {groupList.map((group) => (
                <>
                  <button
                    key={group.groupName}
                    className={styles.Group}
                    onClick={() => handleGroupToggle(group.groupName)}
                  >
                    <p>{t(`notification.group.${group.groupName}`)}</p>
                    <ChevronDownIcon
                      size={16}
                      className={
                        isGroupOpened(group.groupName)
                          ? styles.GroupOpened
                          : styles.GroupClosed
                      }
                    />
                  </button>

                  {group.open &&
                    elements
                      .filter(
                        (element) => element.portalType === group.groupName,
                      )
                      .map((menu) => (
                        <button
                          className={clsx(
                            selectedMenu === menu.appCode
                              ? styles.SelectedMenu
                              : styles.Menu,
                            'ml-2',
                          )}
                          onClick={() =>
                            handleOnChangeMenu(menu.appCode, menu.appName)
                          }
                          key={group.groupName + menu.appCode}
                        >
                          {menu.appName}
                        </button>
                      ))}
                </>
              ))}
            </div>
          </div>

          <div className={styles.ListContainer}>
            <div className={styles.ListHeader}>
              <p className={styles.ListHeaderTitle}>{menuTitle}</p>
              {selectedMenu === 'unread' && (
                <Button
                  size="sm"
                  shape="round"
                  variant="outline"
                  colorset="level2"
                  onClick={() => void handleOnReadAll()}
                >
                  <CheckIcon size={16} />
                  {t('notification.readAll')}
                </Button>
              )}
              {(selectedMenu === 'all' || selectedMenu === 'unread') && (
                <Button
                  size="sm"
                  shape="round"
                  variant="outline"
                  colorset="level2"
                  onClick={() => void handleOnDeleteAll()}
                >
                  <DeleteIcon size={16} />
                  {t('notification.deleteAll')}
                </Button>
              )}
              {(selectedMenu === 'all' || selectedMenu === 'unread') && (
                <div className={styles.Divider} />
              )}

              <Button
                size="sm"
                shape="round"
                variant="outline"
                colorset="level2"
              >
                <SettingsIcon size={16} />
                {t('notification.setting')}
              </Button>
            </div>
            <InfinityNotificationList menu={selectedMenu} />
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default NotificationList;
