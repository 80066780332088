import { PropsWithChildren } from 'react';
import * as Popover from '../../shared/ui/popover';
import NotificationLayer from './layer';

/**
 * @description User-
 * @param children UserNotification 레이어가 노출되기 위한 Trigger
 */
function UserNotification({ children }: PropsWithChildren) {
  return (
    <Popover.Root modal={true}>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content isPortal align="end" sideOffset={10}>
        <NotificationLayer />
      </Popover.Content>
    </Popover.Root>
  );
}

export default UserNotification;
