import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { FolderUpIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button, IconButton } from '@dop-ui/react/shared/ui/button';
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { QueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { formatFileNameWithDate } from '../../../../../../../../lib/utils/date/withLocale';
import OrgChart from '../../../../../../../OrgChart';
import { NodeProps } from '../../../../../../../OrgChart/interface';
import { dataDownloadQuery } from '../../../api/dataDownloadQuery';
import { AppType } from '../../../types';
import { useContext } from '../../Provider';

export function Header() {
  const {
    selectedAppType,
    deptId,
    deptName,
    selectedRows,
    setSelectedAppType,
  } = useContext();
  const { t } = useTranslation('component');
  const isDisabled = selectedRows.length === 0;
  const [deptSelectOpenState, setDeptSelectOpenState] = useState(false);
  const [, setDesignatedDeptId] = useState<number>();
  const [, setDesignatedDeptName] = useState<string>();

  const titleTextRecord: Record<AppType, string> = {
    approval: t('globalconfig.dataManagement.deptManagement.approval.title'),
    board: t('globalconfig.dataManagement.deptManagement.board.title'),
    report: t('globalconfig.dataManagement.deptManagement.report.title'),
    group: t('globalconfig.dataManagement.deptManagement.group.title'),
  };

  const approvalTextRecord: Record<AppType, string> = {
    approval: t('globalconfig.dataManagement.deptManagement.moveApproval'),
    board: t('globalconfig.dataManagement.deptManagement.moveBoard'),
    report: t('globalconfig.dataManagement.deptManagement.moveReport'),
    group: t('globalconfig.dataManagement.deptManagement.moveGroup'),
  };

  const onClickDownloadButtonHandler = async () => {
    try {
      const queryClient = new QueryClient();
      await queryClient
        .fetchQuery({
          queryKey: ['dataDownloadQuery', deptId, selectedAppType],
          queryFn: () => dataDownloadQuery(deptId, selectedAppType),
        })
        .then((blob: Blob | undefined) => blob && blobHandler(blob));
    } catch (error) {
      console.error(error);
    }
  };

  const blobHandler = (blob: Blob) => {
    const blobURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = formatFileNameWithDate(
      t(`globalconfig.data.dept.deleted.download.title`),
      new Date(),
      'csv',
    );
    link.click();
  };

  const nodeSelectHandler = (node: NodeProps) => {
    setDeptSelectOpenState(false);
    const selectedNodeName =
      node.nodeType === 'DEPARTMENT'
        ? node.name
        : node?.departmentName ?? node.name;
    setDesignatedDeptName(selectedNodeName);
    node.departmentId && setDesignatedDeptId(node.departmentId);
    if (
      confirm(
        selectedAppType &&
          titleTextRecord[selectedAppType] &&
          titleTextRecord[selectedAppType].replace(
            '{deptName}',
            selectedNodeName ?? '',
          ),
      )
    ) {
      // const queryClient = new QueryClient();
      // await queryClient.fetchQuery({
      // })
      // TODO: 이관 API 기능 동작 후, 추가 필요
    }
  };

  const titleBlock = (
    <div className="flex items-center">
      <IconButton
        className="rounded-xl"
        onClick={() => setSelectedAppType(undefined)}
        icon={() => <ArrowLeftIcon className="w-6 h-6" />}
      />
      <h2 className="ms-2 font-medium text-[22px] -tracking-[0.88px] mr-3">
        {selectedAppType && titleTextRecord[selectedAppType]}
      </h2>
      <hr className="!m-0 w-[1px] h-3 bg-[#E2E5E9]" />
      <span className="font-normal ml-3 text-base text-[#AAA]">{deptName}</span>
    </div>
  );

  const deptSelectBlock = (
    <Dialog.Root
      open={deptSelectOpenState}
      onOpenChange={setDeptSelectOpenState}
    >
      <Dialog.Trigger>
        <Button
          size="md"
          shape="rect"
          variant="outline"
          colorset="level2"
          // TODO: 이관 API(문서함, 게시판, 보고서, 주소록) 연동 필요.
          onClick={() =>
            selectedRows.length > 0 && alert('아직 준비중인 기능입니다.')
          }
        >
          <FolderUpIcon
            className={clsx('size-5', {
              'stroke-[#AAAAAA]': isDisabled,
              'stroke-[#363636]': !isDisabled,
            })}
          />
          {selectedAppType && approvalTextRecord[selectedAppType]}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="resizeable">
        <OrgChart
          style={{
            top: '256px',
            right: '92px',
            left: 'auto',
            bottom: '18px',
            width: '300px',
          }}
          onNodeClick={nodeSelectHandler}
          useMultiSelect={false}
        />
      </Dialog.Content>
    </Dialog.Root>
  );

  const actionButtonListBlock = (
    <div className="flex items-center gap-2">
      {/* TODO: Context 지정해, Row 선택 없을 시 disabled */}
      {deptSelectBlock}
      <Button
        colorset="level2"
        variant="outline"
        onClick={() => void onClickDownloadButtonHandler()}
        size="md"
        shape="rect"
      >
        <ArrowDownTrayIcon className="size-4 stroke-[#363636]" />
        {t('globalconfig.common.button.downloadList.title')}
      </Button>
    </div>
  );

  const approvalRouteBlock = (
    <div className="flex items-center gap-2">
      <span className="text-sm text-[#AAA] font-normal -tracking-[0.28px]">
        {t('globalconfig.dataManagement.deptManagement.approval.help')}
      </span>
      {/* TODO: 바로가기 URL 생성시 추가 필요 */}
      <Link
        className="flex h-6 py-[2px] ps-2 pe-1 items-center bg-white border border-[#DEDEDE] rounded-lg gap-[2px]"
        href={''}
      >
        {t('globalconfig.common.button.shortcut.title')}
        {<ChevronRightIcon className="w-3 h-3 stroke-[#A3A3A3]" />}
      </Link>
    </div>
  );

  useEffect(() => {
    selectedRows.length === 0 && setDeptSelectOpenState(false);
  }, [setDeptSelectOpenState, selectedRows]);

  return (
    <>
      <div className="flex items-center justify-between">
        {titleBlock}
        {actionButtonListBlock}
      </div>
      {selectedAppType === 'approval' && approvalRouteBlock}
    </>
  );
}

export default Header;
