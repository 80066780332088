import { PROFILE_CARD_CONFIG_API } from '../../constants';
import { BaseResponse } from '../../types';
import { ProfileConfigInfo } from '../type';
import fetch from '@dop-ui/react/shared/lib/fetch/client';

export const QUERY_KEY =
  'GlobalConfig.BasicManagement.ProfileManagement.ProfileConfig';

export async function getProfileCardConfig(): Promise<ProfileConfigInfo> {
  try {
    const response = await fetch(PROFILE_CARD_CONFIG_API, {
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    });

    if (!response.ok) {
      throw new Error('getProfileCardConfig 오류 발생.');
    }

    const resData = (await response.json()) as BaseResponse<ProfileConfigInfo>;

    return resData.data;
  } catch (error) {
    console.log('error', error);
    return {
      editedProfile: true,
      profileCardItemDisplayDtos: [
        {
          id: 0,
          name: 'string',
          custom: true,
          display: true,
        },
      ],
    };
  }
}
