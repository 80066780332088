import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useProfileManagementContext } from '../../context';

export function CustomProfileManagement() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();
  const {
    profileManagementContext: {
      profileConfig: { profileCardItemDisplayDtos },
    },
  } = useProfileManagementContext();

  const customProfileCount = profileCardItemDisplayDtos.filter(
    (item) => item.custom,
  ).length;

  return (
    <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex justify-between items-center">
        <p className="text-[#333333] text-[22px] font-[500]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement',
          )}
        </p>
        <div className="flex gap-[24px] justify-center items-center">
          <p className="text-[12px] text-[#888]">
            {customProfileCount}
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement.count',
            )}
          </p>
          <Button
            className="!w-[12px] !h-[12px]"
            onClick={() => stacker.push('customProfile')}
          >
            <ChevronRightIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomProfileManagement;
