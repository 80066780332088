import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Provider } from 'jotai';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './OrgChart';
import { Provider as OrgChartProvider } from './Provider';
import { NodeProps } from './interface';
import { generateUUID } from '../../lib/utils/util';
import { NORMAL } from './constants';

export interface Props {
  type?: string;
  title?: string;
  url?: string;
  dataConverter?: () => Array<NodeProps>;
  useHeader?: boolean;
  useFooter?: boolean;
  useMultiSelect?: boolean;
  useResize?: boolean;
  useGroupOrg?: boolean;
  companyId?: number;
  selectableNodeType?: string;
  onClose?: () => void;
  onConfirm?: (nodes: NodeProps[]) => void;
  onNodeClick?: (node: NodeProps) => void;
  setLastSelectedNode?: Dispatch<SetStateAction<NodeProps | undefined>>;
  style?: CSSProperties;
}

export default function OrgChart({
  type = NORMAL,
  title,
  url,
  dataConverter,
  useHeader = true,
  useFooter = true,
  useMultiSelect = true,
  useResize = false,
  useGroupOrg = false,
  //TODO: 추후 분기처리 필요
  companyId,
  selectableNodeType = NORMAL,
  onClose,
  onConfirm,
  onNodeClick,
  setLastSelectedNode,
  style,
}: Props) {
  const uuid = generateUUID();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <OrgChartProvider
          type={type}
          onNodeClick={onNodeClick}
          setLastSelectedNode={setLastSelectedNode}
          useMultiSelect={useMultiSelect}
          selectableNodeType={selectableNodeType}
          uuid={uuid}
        >
          <App
            title={title}
            type={type}
            url={url}
            dataConverter={dataConverter}
            useHeader={useHeader}
            useFooter={useFooter}
            useMultiSelect={useMultiSelect}
            useResize={useResize}
            //TODO: 추후 분기처리 필요
            companyId={companyId}
            useGroupOrg={useGroupOrg}
            style={style}
            onClose={onClose}
            onConfirm={onConfirm}
          ></App>
        </OrgChartProvider>
      </Provider>
    </QueryClientProvider>
  );
}
