import { useAtomValue, useSetAtom } from 'jotai';
import { PropsWithChildren, useEffect } from 'react';
import { setMenuByPathAtom } from '../../store/setMenuByPathAtom';
import { isContentNeedsSaveAtom } from '../../store/isContentNeedsSaveAtom';
import { Button } from '@dop-ui/react/shared/ui/button';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24/Close';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { SaveConfirmDialog } from '../components/save-confirm-dialog';

export interface Props {
  configPath?: string;
  onClosed: () => void;
}

export function Container({
  configPath,
  onClosed,
  children,
}: PropsWithChildren<Props>) {
  const setMenuByPath = useSetAtom(setMenuByPathAtom);
  const isContentsNeedsSave = useAtomValue(isContentNeedsSaveAtom);
  const { confirm } = useMessageDialog();

  useEffect(() => {
    const preventNotSaveSituation = (e: BeforeUnloadEvent) => {
      if (isContentsNeedsSave) e.preventDefault();
    };

    window.addEventListener('beforeunload', preventNotSaveSituation);
    return () => {
      window.removeEventListener('beforeunload', preventNotSaveSituation);
    };
  }, [isContentsNeedsSave]);

  useEffect(() => {
    if (!configPath) return;
    setMenuByPath(configPath);
  }, [configPath, setMenuByPath]);

  useEffect(() => {
    return () => {
      setMenuByPath();
    };
  }, [setMenuByPath]);

  const onClickCloseButton = async () => {
    if (isContentsNeedsSave) {
      if (await confirm(<SaveConfirmDialog />)) onClosed();
      else return;
    } else {
      onClosed();
    }
  };

  return (
    <div className="relative flex size-full">
      <Button
        className="absolute top-[40px] right-[40px] z-[1001]"
        onClick={() => void onClickCloseButton()}
      >
        <CloseIcon />
      </Button>
      {children}
    </div>
  );
}
