import { Dispatch, SetStateAction, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useSuspenseQuery } from '@tanstack/react-query';
import * as getMultiMemberListQueries from '../apis/get-multi-member-list';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useAuthContext } from '../../../../../../lib/auth/client';

import Avatar from '@dop-ui/react/shared/ui/avatar';
import MultiMemberSetting from '../multi-member-setting';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';

import { TRANSLATION_PREFIX } from '../constants';
import styles from './multi-member-list.module.css';
import type { MultiMember, MultiMemberElements } from '../types';
import { useMultiMemberContext } from '../context';
interface Props {
  selectedMultiMember: MultiMemberElements[];
  setSelectedMultiMember: Dispatch<SetStateAction<MultiMemberElements[]>>;
}

function MultiMemberList({ setSelectedMultiMember }: Props) {
  const { t } = useTranslation('component');
  const { me } = useAuthContext();

  const { page, pageOffset, setPage } = useMultiMemberContext();

  const response = useSuspenseQuery({
    queryKey: [getMultiMemberListQueries.QUERY_KEY, page, pageOffset],
    queryFn: async () =>
      await getMultiMemberListQueries.getMultiMemberList({
        page,
        size: pageOffset,
        companyGroupId: me?.companyGroupId,
      }),
  });

  const data = response.data?.elements;
  const totalPages = response.data?.page.totalPages;

  const [updateConfirmOpen, setUpdateConfirmOpen] = useState(false);

  const [integrationId, setIntegrationId] = useState(-1);
  const [integrateDatas, setIntegrateDatas] = useState<MultiMember[]>([]);

  if (!me) {
    return;
  }

  // TODO: 조직도에서 내려오는 데이터 변경 후 적용 필요
  const columnDefs: ColumnDef<MultiMemberElements>[] = [
    {
      id: 'userName',
      accessorFn: (data: MultiMemberElements) => data.userList,
      header: () => (
        <div className={styles.TableHeader}>
          {t(`${TRANSLATION_PREFIX}.table.column.name`)}
        </div>
      ),

      cell: (data) => {
        const memberList = data.getValue() as MultiMember[];
        return (
          <div className={styles.TableCell}>
            {memberList.map((member, idx) => (
              <div key={member.loginId + idx} className="flex gap-2">
                <Avatar
                  alt={member.userName}
                  src={member.profileImageUrl}
                  size={24}
                />
                <p> {member.userName}</p>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      id: 'account',
      accessorFn: (data: MultiMemberElements) => data.userList,
      header: () => (
        <div className={styles.TableHeader}>
          {t(`${TRANSLATION_PREFIX}.table.column.account`)}
        </div>
      ),
      cell: (data) => {
        const memberList = data.getValue() as MultiMember[];
        return (
          <div className={styles.TableCell}>
            {memberList.map((member) => (
              <p key={`${member.companyId}-${member.loginId}`}>
                {member.loginId}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      id: 'position',
      accessorFn: (data: MultiMemberElements) => data.userList,
      header: () => (
        <div className={styles.TableHeader}>
          {t(`${TRANSLATION_PREFIX}.table.column.position`)}
        </div>
      ),
      cell: (data) => {
        const memberList = data.getValue() as MultiMember[];
        return (
          <div className={styles.TableCell}>
            {memberList.map((member) => (
              <p key={`position-${member.companyId}-${member.loginId}`}>
                {member.positionName}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      id: 'grade',
      accessorFn: (data: MultiMemberElements) => data.userList,
      header: () => (
        <div className={styles.TableHeader}>
          {t(`${TRANSLATION_PREFIX}.table.column.grade`)}
        </div>
      ),
      cell: (data) => {
        const memberList = data.getValue() as MultiMember[];
        return (
          <div className={styles.TableCell}>
            {memberList.map((member) => (
              <p key={`grade-${member.companyId}-${member.loginId}`}>
                {member.gradeName}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      id: 'site',
      accessorFn: (data: MultiMemberElements) => data.userList,
      header: () => (
        <div className={styles.TableHeader}>
          {t(`${TRANSLATION_PREFIX}.table.column.site`)}
        </div>
      ),

      cell: (data) => {
        const memberList = data.getValue() as MultiMember[];
        return (
          <div className={styles.TableCell}>
            {memberList.map((member) => (
              <p key={`company-${member.companyId}-${member.loginId}`}>
                {member.companyName}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  const handleOnClickRow = (rowIdx: number) => {
    if (data) {
      if (!data[rowIdx]) return;

      setIntegrationId(data[rowIdx].integrationId);
      setIntegrateDatas(data[rowIdx].userList);
      setUpdateConfirmOpen(true);
    }
  };

  return (
    <>
      <TablePrimitives.Root<MultiMemberElements>
        selectable
        onSelectContents={setSelectedMultiMember}
        columnDefs={columnDefs}
        contents={data || []}
        onClickRow={handleOnClickRow}
      >
        <TablePrimitives.Contents
          emptyNotice={
            <p className={styles.EmptyMessage}>
              {t(`${TRANSLATION_PREFIX}.empty`)}
            </p>
          }
        />
        <TablePrimitives.Pagination
          className="mt-6 py-4"
          currentPage={page}
          onNextPage={setPage}
          onPreviousPage={setPage}
          onClickPage={setPage}
          onFirstPage={() => setPage(0)}
          onLastPage={() => {
            setPage((totalPages || 1) - 1);
          }}
          totalPage={totalPages || 0}
        />
      </TablePrimitives.Root>
      <MultiMemberSetting
        title={t(`${TRANSLATION_PREFIX}.setting`)}
        open={updateConfirmOpen}
        onOpenChange={setUpdateConfirmOpen}
        initialValue={integrateDatas}
        integrationId={integrationId}
      />
    </>
  );
}
export default MultiMemberList;
