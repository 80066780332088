import { PROFILE_CARD_DISPLAY_ITEM_API } from '../../constants';
import { ProfileCardItemDisplayDto } from '../type';
import fetch from '@dop-ui/react/shared/lib/fetch/client';

export async function updateDisplayItem(
  displayItem: ProfileCardItemDisplayDto,
) {
  try {
    const response = await fetch(PROFILE_CARD_DISPLAY_ITEM_API, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
      body: JSON.stringify(displayItem),
    });

    if (!response.ok) {
      throw new Error('updateDisplayItem 오류 발생.');
    }

    return response;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
}
