export const mockedCalendarShareList = {
  hasNext: false,
  message: 'OK',
  code: '200',
  __go_checksum__: true,
  data: [
    {
      id: 101,
      companyId: 402,
      companyName: 'daouoffice01 Company',
      name: 'daouoffice01 Company 캘린더1',
      companyGroupId: 0,
      shares: [
        {
          id: 0,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'company',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우다우다우',
              nodeValue: '다우다우다우',
              actions: 'read',
              members: [],
              id: 0,
            },
          ],
        },
        {
          id: 1,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 1,
              nodeType: 'user',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '키움기술',
              nodeValue: '김다다',
              actions: 'read,write',
              members: [],
              id: 1,
            },
          ],
        },
        {
          id: 2,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 2,
              nodeType: 'duty',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우증권',
              nodeValue: '책임',
              actions: 'read',
              members: [],
              id: 2,
            },
          ],
        },
        {
          id: 3,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 3,
              nodeType: 'usergroup',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '키움키움키움',
              nodeValue: 'A그룹',
              actions: 'read,write',
              members: [],
              id: 3,
            },
          ],
        },
      ],
      share: {
        nodeId: 0,
        nodeType: 'nodeType_ecc26d5371dd',
        nodeDeptId: 0,
        nodeDeptName: 'nodeDeptName_04b5f99ee99e',
        nodeThumbnail: 'nodeThumbnail_f2f3f49ad357',
        nodeCompanyId: 0,
        nodeCompanyName: 'nodeCompanyName_17ddf4745a3e',
        nodeValue: 'nodeValue_c1bad945137e',
        actions: 'actions_ce6802643b54',
        members: [],
        id: 0,
      },
    },
    {
      id: 102,
      companyId: 402,
      companyName: 'daouoffice01 Company',
      name: 'daouoffice01 Company 캘린더2',
      companyGroupId: 0,
      shares: [
        {
          id: 0,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'company',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우다우다우',
              nodeValue: '다우다우다우',
              actions: 'read',
              members: [],
              id: 0,
            },
          ],
        },
        {
          id: 1,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 1,
              nodeType: 'user',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '키움기술',
              nodeValue: '김다다',
              actions: 'read,write',
              members: [],
              id: 1,
            },
          ],
        },
        {
          id: 2,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 2,
              nodeType: 'duty',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우증권',
              nodeValue: '책임',
              actions: 'read',
              members: [],
              id: 2,
            },
          ],
        },
      ],
      share: {
        nodeId: 0,
        nodeType: 'nodeType_ecc26d5371dd',
        nodeDeptId: 0,
        nodeDeptName: 'nodeDeptName_04b5f99ee99e',
        nodeThumbnail: 'nodeThumbnail_f2f3f49ad357',
        nodeCompanyId: 0,
        nodeCompanyName: 'nodeCompanyName_17ddf4745a3e',
        nodeValue: 'nodeValue_c1bad945137e',
        actions: 'actions_ce6802643b54',
        members: [],
        id: 0,
      },
    },
    {
      id: 201,
      companyId: 452,
      companyName: 'daouoffice02 Company',
      name: 'daouoffice02 Company 캘린더1',
      companyGroupId: 0,
      shares: [
        {
          id: 0,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'company',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우다우다우',
              nodeValue: '다우다우다우',
              actions: 'read',
              members: [],
              id: 0,
            },
          ],
        },
        {
          id: 1,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 1,
              nodeType: 'user',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '키움기술',
              nodeValue: '김다다',
              actions: 'read,write',
              members: [],
              id: 1,
            },
          ],
        },
      ],
      share: {
        nodeId: 0,
        nodeType: 'nodeType_ecc26d5371dd',
        nodeDeptId: 0,
        nodeDeptName: 'nodeDeptName_04b5f99ee99e',
        nodeThumbnail: 'nodeThumbnail_f2f3f49ad357',
        nodeCompanyId: 0,
        nodeCompanyName: 'nodeCompanyName_17ddf4745a3e',
        nodeValue: 'nodeValue_c1bad945137e',
        actions: 'actions_ce6802643b54',
        members: [],
        id: 0,
      },
    },
    {
      id: 0,
      companyId: 0,
      companyName: '다우기술',
      name: '전사 주요 일정',
      companyGroupId: 0,
      shares: [
        {
          id: 0,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'company',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우다우다우',
              nodeValue: '다우다우다우',
              actions: 'read',
              members: [],
              id: 0,
            },
          ],
        },
        {
          id: 1,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 1,
              nodeType: 'user',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '키움기술',
              nodeValue: '김다다',
              actions: 'read,write',
              members: [],
              id: 1,
            },
          ],
        },
        {
          id: 2,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 2,
              nodeType: 'duty',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '다우증권',
              nodeValue: '책임',
              actions: 'read',
              members: [],
              id: 2,
            },
          ],
        },
        {
          id: 3,
          companyUuid: 'companyUuid_41e57cf6b8da',
          discription: 'discription_a6c936bc564e',
          nodes: [
            {
              nodeId: 3,
              nodeType: 'usergroup',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_704c34f42b7d',
              nodeThumbnail: 'nodeThumbnail_ce91ed291069',
              nodeCompanyId: 0,
              nodeCompanyName: '키움키움키움',
              nodeValue: 'A그룹',
              actions: 'read,write',
              members: [],
              id: 3,
            },
          ],
        },
      ],
      share: {
        nodeId: 0,
        nodeType: 'nodeType_ecc26d5371dd',
        nodeDeptId: 0,
        nodeDeptName: 'nodeDeptName_04b5f99ee99e',
        nodeThumbnail: 'nodeThumbnail_f2f3f49ad357',
        nodeCompanyId: 0,
        nodeCompanyName: 'nodeCompanyName_17ddf4745a3e',
        nodeValue: 'nodeValue_c1bad945137e',
        actions: 'actions_ce6802643b54',
        members: [],
        id: 0,
      },
    },
    {
      id: 1544,
      companyId: 652,
      companyName: '20240312',
      name: '전사 공지',
      companyGroupId: 70,
      shares: [
        {
          id: 33673,
          nodes: [
            {
              id: 12294,
              nodeId: 2692,
              nodeType: 'subdepartment',
              nodeDeptId: 2692,
              nodeDeptName: '박씨모임',
              nodeCompanyId: 650,
              nodeCompanyName: '20240311생성',
              nodeValue: '박씨모임',
              actions: 'read,write',
              members: [],
            },
          ],
        },
        {
          id: 33674,
          nodes: [
            {
              id: 12295,
              nodeId: 2205,
              nodeType: 'user',
              nodeDeptId: 1020,
              nodeDeptName: '테스트부서',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '애진관리자 주임',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1545,
      companyId: 652,
      companyName: '20240312',
      name: '전사 알림',
      companyGroupId: 70,
      shares: [
        {
          id: 33647,
          nodes: [
            {
              id: 12249,
              nodeId: 2202,
              nodeType: 'user',
              nodeDeptId: 1073,
              nodeDeptName: '상위부서3',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '토스트 ',
              actions: 'read,write',
              members: [],
            },
          ],
        },
        {
          id: 33648,
          nodes: [
            {
              id: 12250,
              nodeId: 151,
              nodeType: 'company',
              nodeCompanyId: 151,
              nodeCompanyName: '형길테스트',
              nodeValue: '형길테스트',
              actions: 'read,write',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1996,
      companyId: 802,
      companyName: '거인기술',
      name: '전사 공지',
      companyGroupId: 70,
      shares: [
        {
          id: 33666,
          nodes: [
            {
              id: 12283,
              nodeId: 2205,
              nodeType: 'user',
              nodeDeptId: 1020,
              nodeDeptName: '테스트부서',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '애진관리자 주임',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1997,
      companyId: 802,
      companyName: '거인기술',
      name: '전사 알림',
      companyGroupId: 70,
      shares: [
        {
          id: 33613,
          nodes: [
            {
              id: 12215,
              nodeId: 151,
              nodeType: 'company',
              nodeCompanyId: 151,
              nodeCompanyName: '형길테스트',
              nodeValue: '형길테스트',
              actions: 'read,write',
              members: [],
            },
            {
              id: 12216,
              nodeId: 2470,
              nodeType: 'department',
              nodeDeptId: 2470,
              nodeDeptName: 'baes',
              nodeCompanyId: 151,
              nodeCompanyName: '형길테스트',
              nodeValue: 'baes',
              actions: 'read',
              members: [],
            },
          ],
        },
        {
          id: 33614,
          nodes: [
            {
              id: 12217,
              nodeId: 450,
              nodeType: 'grade',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원3',
              actions: 'read,write',
              members: [],
            },
            {
              id: 12218,
              nodeId: 455,
              nodeType: 'position',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '주임',
              actions: 'read,write',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1110,
      companyId: 450,
      companyName: '김송희1',
      name: '전사 공지',
      companyGroupId: 70,
      shares: [
        {
          id: 33639,
          nodes: [
            {
              id: 12235,
              nodeId: 1073,
              nodeType: 'department',
              nodeDeptId: 1073,
              nodeDeptName: '상위부서3',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '상위부서3',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1995,
      companyId: 10,
      companyName: '다우테크',
      name: 'testtestest',
      companyGroupId: 70,
      shares: [
        {
          id: 33622,
          nodes: [
            {
              id: 12228,
              nodeId: 370,
              nodeType: 'company',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '111',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1242,
      companyId: 10,
      companyName: '다우테크',
      name: '권한테스트',
      companyGroupId: 70,
      shares: [
        {
          id: 21826,
          nodes: [
            {
              id: 9248,
              nodeId: 200,
              nodeType: 'company',
              nodeCompanyId: 200,
              nodeCompanyName: '류길동회사',
              nodeValue: '류길동회사',
              actions: 'read,write',
              members: [],
            },
          ],
        },
        {
          id: 21827,
          nodes: [
            {
              id: 9249,
              nodeId: 370,
              nodeType: 'company',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '111',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1716,
      companyId: 10,
      companyName: '다우테크',
      name: '멀티 테스트 게시판',
      companyGroupId: 70,
      shares: [
        {
          id: 33410,
          nodes: [
            {
              id: 12122,
              nodeId: 652,
              nodeType: 'company',
              nodeCompanyId: 652,
              nodeCompanyName: '20240312',
              nodeValue: '20240312',
              actions: 'read',
              members: [],
            },
          ],
        },
        {
          id: 33411,
          nodes: [
            {
              id: 12123,
              nodeId: 1071,
              nodeType: 'subdepartment',
              nodeDeptId: 1071,
              nodeDeptName: '상위부서2',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '상위부서2',
              actions: 'read',
              members: [],
            },
            {
              id: 12124,
              nodeId: 456,
              nodeType: 'position',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원',
              actions: 'read,write',
              members: [],
            },
            {
              id: 12125,
              nodeId: 450,
              nodeType: 'grade',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원3',
              actions: 'read',
              members: [],
            },
            {
              id: 12126,
              nodeId: 453,
              nodeType: 'duty',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '대표이사',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1063,
      companyId: 10,
      companyName: '다우테크',
      name: '멀티컴퍼니 공유게시판',
      companyGroupId: 70,
      shares: [
        {
          id: 33803,
          nodes: [
            {
              id: 12301,
              nodeId: 200,
              nodeType: 'company',
              nodeCompanyId: 200,
              nodeCompanyName: '류길동회사',
              nodeValue: '류길동회사',
              actions: 'read',
              members: [],
            },
          ],
        },
        {
          id: 33804,
          nodes: [
            {
              id: 12302,
              nodeId: 24495,
              nodeType: 'user',
              nodeDeptId: 980,
              nodeDeptName: '볼빨간사춘기',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 380,
              nodeCompanyName: '볼빨간사춘기',
              nodeValue: '웬디 사원',
              actions: 'read,write',
              members: [],
            },
          ],
        },
        {
          id: 33805,
          nodes: [
            {
              id: 12303,
              nodeId: 829,
              nodeType: 'duty',
              nodeCompanyId: 591,
              nodeCompanyName: '지현테스트',
              nodeValue: '대표이사',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1430,
      companyId: 10,
      companyName: '다우테크',
      name: '부서게시판',
      companyGroupId: 70,
      shares: [
        {
          id: 33369,
          nodes: [
            {
              id: 12057,
              nodeId: 456,
              nodeType: 'position',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1021,
      companyId: 10,
      companyName: '다우테크',
      name: '삭제2 피드',
      companyGroupId: 70,
      shares: [
        {
          id: 33801,
          nodes: [
            {
              id: 12297,
              nodeId: 453,
              nodeType: 'duty',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '대표이사',
              actions: 'read',
              members: [],
            },
            {
              id: 12298,
              nodeId: 454,
              nodeType: 'duty',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '팀장',
              actions: 'read',
              members: [],
            },
            {
              id: 12299,
              nodeId: 370,
              nodeType: 'company',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '111',
              actions: 'read',
              members: [],
            },
          ],
        },
        {
          id: 33802,
          nodes: [
            {
              id: 12300,
              nodeId: 652,
              nodeType: 'company',
              nodeCompanyId: 652,
              nodeCompanyName: '20240312',
              nodeValue: '20240312',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1065,
      companyId: 10,
      companyName: '다우테크',
      name: '자식그룹확인',
      companyGroupId: 70,
      shares: [
        {
          id: 33644,
          nodes: [
            {
              id: 12243,
              nodeId: 453,
              nodeType: 'duty',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '대표이사',
              actions: 'read,write',
              members: [],
            },
            {
              id: 12244,
              nodeId: 456,
              nodeType: 'position',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원',
              actions: 'read',
              members: [],
            },
            {
              id: 12245,
              nodeId: 2202,
              nodeType: 'user',
              nodeDeptId: 1071,
              nodeDeptName: '상위부서2',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '토스트 ',
              actions: 'read',
              members: [],
            },
            {
              id: 12246,
              nodeId: 370,
              nodeType: 'company',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '111',
              actions: 'read,write',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1616,
      companyId: 10,
      companyName: '다우테크',
      name: '테스트테스트',
      companyGroupId: 70,
      shares: [
        {
          id: 33391,
          nodes: [
            {
              id: 12075,
              nodeId: 28476,
              nodeType: 'user',
              nodeDeptId: 1010,
              nodeDeptName: 'qq',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 151,
              nodeCompanyName: '형길테스트',
              nodeValue: 'qq3 과장',
              actions: 'read',
              members: [],
            },
            {
              id: 12076,
              nodeId: 2470,
              nodeType: 'department',
              nodeDeptId: 2470,
              nodeDeptName: 'baes',
              nodeCompanyId: 151,
              nodeCompanyName: '형길테스트',
              nodeValue: 'baes',
              actions: 'read',
              members: [],
            },
            {
              id: 12077,
              nodeId: 876,
              nodeType: 'position',
              nodeCompanyId: 151,
              nodeCompanyName: '형길테스트',
              nodeValue: '전무',
              actions: 'read,write',
              members: [],
            },
          ],
        },
        {
          id: 33392,
          nodes: [
            {
              id: 12078,
              nodeId: 582,
              nodeType: 'grade',
              nodeCompanyId: 380,
              nodeCompanyName: '볼빨간사춘기',
              nodeValue: '사원1',
              actions: 'read',
              members: [],
            },
            {
              id: 12079,
              nodeId: 652,
              nodeType: 'duty',
              nodeCompanyId: 380,
              nodeCompanyName: '볼빨간사춘기',
              nodeValue: '전문임원',
              actions: 'read',
              members: [],
            },
            {
              id: 12090,
              nodeId: 457,
              nodeType: 'usergroup',
              nodeCompanyId: 380,
              nodeCompanyName: '볼빨간사춘기',
              nodeValue: '지엠001',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 401,
      companyId: 200,
      companyName: '류길동회사',
      name: '전사 알림',
      companyGroupId: 70,
      shares: [
        {
          id: 33649,
          nodes: [
            {
              id: 12251,
              nodeId: 10,
              nodeType: 'company',
              nodeCompanyId: 10,
              nodeCompanyName: '다우테크',
              nodeValue: '다우테크',
              actions: 'read,write',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 934,
      companyId: 380,
      companyName: '볼빨간사춘기',
      name: '첨부파일이 사라지네?',
      companyGroupId: 70,
      shares: [
        {
          id: 33664,
          nodes: [
            {
              id: 12277,
              nodeId: 10,
              nodeType: 'company',
              nodeCompanyId: 10,
              nodeCompanyName: '다우테크',
              nodeValue: '다우테크',
              actions: 'read',
              members: [],
            },
            {
              id: 12278,
              nodeId: 29178,
              nodeType: 'user',
              nodeDeptId: 52,
              nodeDeptName: '경영지원본부',
              nodeThumbnail: 'resources/images/photo_profile_small.jpg',
              nodeCompanyId: 10,
              nodeCompanyName: '다우테크',
              nodeValue: '정우진 ',
              actions: 'read,write',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 1644,
      companyId: 712,
      companyName: '정유진123',
      name: '전사 공지',
      companyGroupId: 70,
      shares: [
        {
          id: 33406,
          nodes: [
            {
              id: 12115,
              nodeId: 450,
              nodeType: 'company',
              nodeCompanyId: 450,
              nodeCompanyName: '김송희1',
              nodeValue: '김송희1',
              actions: 'read,write',
              members: [],
            },
            {
              id: 12116,
              nodeId: 763,
              nodeType: 'usergroup',
              nodeCompanyId: 450,
              nodeCompanyName: '김송희1',
              nodeValue: '정직원',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
    {
      id: 272,
      companyId: 151,
      companyName: '형길테스트',
      name: '전사 공지',
      companyGroupId: 70,
      shares: [
        {
          id: 33665,
          nodes: [
            {
              id: 12279,
              nodeId: 453,
              nodeType: 'duty',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '대표이사',
              actions: 'read',
              members: [],
            },
            {
              id: 12280,
              nodeId: 454,
              nodeType: 'duty',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '팀장',
              actions: 'read',
              members: [],
            },
            {
              id: 12281,
              nodeId: 450,
              nodeType: 'grade',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원3',
              actions: 'read',
              members: [],
            },
            {
              id: 12282,
              nodeId: 451,
              nodeType: 'grade',
              nodeCompanyId: 370,
              nodeCompanyName: '111',
              nodeValue: '사원2',
              actions: 'read',
              members: [],
            },
          ],
        },
      ],
    },
  ],
};

export const mockedCompanyCalendarList402 = {
  hasNext: false,
  message: 'OK',
  code: '200',
  __go_checksum__: true,
  data: [
    {
      name: 'daouoffice01 Company 캘린더1',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 101,
    },
    {
      name: 'daouoffice01 Company 캘린더2',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 102,
    },
    {
      name: 'daouoffice01 Company 캘린더3',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 103,
    },
  ],
};

export const mockedCompanyCalendarList452 = {
  hasNext: false,
  message: 'OK',
  code: '200',
  __go_checksum__: true,
  data: [
    {
      name: 'daouoffice02 Company 캘린더1',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 201,
    },
    {
      name: 'daouoffice02 Company 캘린더2',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 202,
    },
  ],
};

export const mockedCompanyCalendarList502 = {
  hasNext: false,
  message: 'OK',
  code: '200',
  __go_checksum__: true,
  data: [
    {
      name: 'daouoffice-local Company 캘린더1',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 301,
    },
    {
      name: 'daouoffice-local Company 캘린더2',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 302,
    },
    {
      name: 'daouoffice-local Company 캘린더3',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 303,
    },
  ],
};

export const mockedCompanyCalendarList = {
  hasNext: false,
  message: 'OK',
  code: '200',
  __go_checksum__: true,
  data: [
    {
      name: '목업 캘린더1',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 0,
    },
    {
      name: '목업 캘린더2',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 1,
    },
    {
      name: '목업 캘린더3',
      companyShares: [
        {
          companyUuid: 'companyUuid_3185464f6b1f',
          discription: 'discription_ee1e1cf3b9e7',
          nodes: [
            {
              nodeId: 0,
              nodeType: 'nodeType_025c66ba3e4b',
              nodeDeptId: 0,
              nodeDeptName: 'nodeDeptName_3894bbda77ff',
              nodeThumbnail: 'nodeThumbnail_64886eb42e06',
              nodeCompanyId: 0,
              nodeCompanyName: 'nodeCompanyName_e84619935537',
              nodeValue: 'nodeValue_4c0c4f3b284f',
              actions: 'actions_63d9d68b29c6',
              members: [],
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      id: 2,
    },
  ],
};
