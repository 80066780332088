import fetch from '../../../shared/lib/fetch/client';
import type { NotificationListResponse } from '../types';

interface Params {
  previousLastId?: number;
  sliceSize: number;
  messageApiType?: string;
  appCode: string;
}

export const QUERY_KEY = 'QueryKeys.Notification.List';

export async function getNotificationList({
  previousLastId,
  sliceSize,
  messageApiType = 'APP',
  appCode,
}: Params): Promise<NotificationListResponse | null> {
  const query = new URLSearchParams();
  if (previousLastId && previousLastId >= 0)
    query.append('previousLastId', previousLastId.toString());
  query.append('sliceSize', sliceSize.toString());
  query.append('messageApiType', messageApiType.toString());
  let url = '/api/portal/common/notification';

  if (appCode !== 'all') {
    if (appCode === 'unread') url += '/unread';
    else {
      query.append('appCode', appCode.toString());
    }
  }

  const response = await fetch(`${url}?${query.toString()}`);

  const { data } = (await response.json()) as {
    data: NotificationListResponse;
  };

  return data;
}
