import * as Stacker from '@daouoffice/ui/lib/foundation/Stacker';
import { FirstPage } from './first-page';
import { Provider as ProfileManagementProvider } from './context';
import { Suspense } from 'react';
import CustomProfileList from './components/custom-profile-management/custom-profile-list';

export function ProfileManagement() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProfileManagementProvider>
        <div className="px-[72px] py-[40px] overflow-y-scroll">
          <Stacker.Root defaultPage="firstPage">
            <Stacker.Content name="firstPage">
              <FirstPage />
            </Stacker.Content>
            <Stacker.Content name="customProfile">
              <CustomProfileList />
            </Stacker.Content>
            <Stacker.Content name="customProfileDetail"></Stacker.Content>
          </Stacker.Root>
        </div>
      </ProfileManagementProvider>
    </Suspense>
  );
}
