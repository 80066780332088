import { CalendarIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import { enUS, ja, ko, vi, zhCN, zhTW } from 'date-fns/locale';
import { forwardRef, PropsWithChildren, useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerPrimitive } from './primitives';
import { CustomInputInfo } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

export interface Props {
  locale?: string;
  className?: string;
  defaultDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  withBorder?: boolean;
  onDateSelect?: (date: Date | null) => void;
}

/**
 * DatePicker 컴포넌트
 * @returns
 */
export function DatePicker({
  locale = 'ko',
  className,
  defaultDate,
  minDate,
  maxDate,
  withBorder,
  onDateSelect,
  children,
}: PropsWithChildren<Props>) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    defaultDate ?? new Date(),
  );

  useEffect(() => {
    if (defaultDate) {
      setSelectedDate(new Date(defaultDate));
    }
  }, [defaultDate]);

  const CustomInput = forwardRef<HTMLButtonElement, CustomInputInfo>(
    ({ onClick }, ref) => (
      <button ref={ref} onClick={onClick}>
        {children}
      </button>
    ),
  );
  CustomInput.displayName = 'CustomInput';

  const onChangeHandler = (date: Date | null) => {
    setSelectedDate(date);
    onDateSelect && onDateSelect(date);
  };

  const registerLocaleHandler = () => {
    if (locale === 'ko') {
      registerLocale(locale, ko);
    } else if (locale === 'en') {
      registerLocale(locale, enUS);
    } else if (locale === 'ja') {
      registerLocale(locale, ja);
    } else if (locale === 'zh-CN') {
      registerLocale(locale, zhCN);
    } else if (locale === 'zh-TW') {
      registerLocale(locale, zhTW);
    } else if (locale === 'vi') {
      registerLocale(locale, vi);
    }
  };

  useEffect(() => {
    if (minDate && selectedDate && minDate > selectedDate) {
      setSelectedDate(minDate);
    }
  }, [minDate, selectedDate]);

  registerLocaleHandler();

  return children ? (
    <ReactDatePicker
      dateFormat="yyyy-MM-dd"
      shouldCloseOnSelect
      locale={locale}
      className={clsx('bg-white', className)}
      selected={selectedDate}
      onChange={onChangeHandler}
      minDate={minDate}
      maxDate={maxDate}
      customInput={<CustomInput />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        increaseMonth,
        decreaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <DatePickerPrimitive.Header
          date={date}
          changeMonth={changeMonth}
          changeYear={changeYear}
          increaseMonth={increaseMonth}
          decreaseMonth={decreaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
    />
  ) : (
    <div
      className={clsx('w- flex items-center gap-1 py-1 ps-2', {
        'border rounded-md': withBorder,
      })}
    >
      <CalendarIcon className="w-4 h-4" />
      <ReactDatePicker
        dateFormat="yyyy-MM-dd"
        shouldCloseOnSelect
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
        className={clsx('', className)}
        selected={selectedDate}
        onChange={onChangeHandler}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          increaseMonth,
          decreaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <DatePickerPrimitive.Header
            date={date}
            changeMonth={changeMonth}
            changeYear={changeYear}
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
      />
    </div>
  );
}

export default DatePicker;
