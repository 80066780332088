import { fetch } from '../../../../../../lib/fetch/client';
import { MOCK_URL_PATH } from './constants';

export const QUERY_KEY = 'deleteAttachFileMutation';

export async function deleteAttachAllFileMutation(ids?: number[]) {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/department/delete/attach`;
    const res = (
      await fetch(FETCH_URL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ids),
      })
    ).json();
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteReportAttachFileMutation(ids?: number[]) {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/report/folder/attach`;
    const res = (
      await fetch(FETCH_URL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: ids ?? [] }),
      })
    ).json();
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteBoardAttachFileMutation(ids?: number[]) {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/board/attach`;
    const res = (
      await fetch(FETCH_URL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: ids ?? [] }),
      })
    ).json();
    return res;
  } catch (error) {
    console.error(error);
  }
}
