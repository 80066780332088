import { Switch } from '@daouoffice/ui';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import * as lockConfigMutation from '../../../api/lock-config-mutation';
import { AppLockInfo } from '../../../types';
import { useValuesContext } from '../../context';

export interface Props {
  data?: AppLockInfo;
}

export function AppAccessConfig({ data }: Props) {
  const [lockState, setLockState] = useState<boolean>(data?.useLock ?? false);
  const { t } = useTranslation('component');
  const { selectedAppId } = useValuesContext();
  const { info } = useToastMessage();
  const updateLockConfig = useMutation({
    mutationFn: async (isLocked: boolean) => {
      await lockConfigMutation
        .updateLockConfig(selectedAppId, isLocked, data?.isRequiredLock)
        .then(() => isLocked);
      return isLocked;
    },
    onSuccess: (isLocked: boolean) => {
      info(
        isLocked
          ? t('globalconfig.secureManagement.appManagement.approachAllow')
          : t('globalconfig.secureManagement.appManagement.approachReject'),
      );
      setLockState(isLocked);
    },
  });

  useEffect(() => {
    setLockState(data?.useLock ?? false);
  }, [data]);

  return (
    <>
      <div className="flex gap-6 justify-between items-start">
        <h2 className="w-[180px] text-[#11] text-[16px] text-left text-ellipsis font-medium -tracking-[0.96px]">
          {t('globalconfig.secureManagement.appManagement.access.config.title')}
        </h2>
        <span className="text-[#AAA] text-[14px] -tracking-[0.28px] font-normal">
          {data?.isRequiredLock
            ? t(
                'globalconfig.secureManagement.appManagement.access.config.requirelock.description',
              )
            : t(
                'globalconfig.secureManagement.appManagement.access.config.description',
              )}
        </span>
        {data?.isRequiredLock || (
          <Switch
            className="h-[20.687px]"
            checked={lockState}
            onChange={(value) => updateLockConfig.mutate(value)}
            id={`${selectedAppId}`}
          />
        )}
      </div>
      <hr className="w-full my-6" />
    </>
  );
}

export default AppAccessConfig;
