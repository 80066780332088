import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Previous 아이콘
 * @param props
 * @returns
 */
export function PreviousIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} size={12} viewBox="0 0 7 12">
      <path
        d="M6 11L1 6L6 1"
        stroke="#AAAAAA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default PreviousIcon;
