export const QUERY_KEY = 'enableApprovalBetweenSitesQuery';

// TODO: API 개발 후, API 연결 예정
export async function getApprovalBetweenSitesStatus(isEnabled: boolean) {
  try {
    return await Promise.resolve(isEnabled);
  } catch (error) {
    console.error(error);
    return isEnabled;
  }
}
