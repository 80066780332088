import { SERVICE_MAP_CONFIG_API } from '../../constants';
import { ServiceMapConfigRequestInfo } from '../type';
import fetch from '@dop-ui/react/shared/lib/fetch/client';

export async function updateServiceMapConfig(
  serviceMapConfig: ServiceMapConfigRequestInfo,
) {
  try {
    const response = await fetch(SERVICE_MAP_CONFIG_API, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
      body: JSON.stringify(serviceMapConfig),
    });

    if (!response.ok) {
      throw new Error('updateServiceMapConfig 오류 발생.');
    }

    return response;
  } catch (error) {
    console.log('error', error);
  }
}
