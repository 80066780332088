import { Avatar } from '../../../shared/ui/avatar';
import * as ProfileCardPrimitives from './primitives';
import { getRandomColor } from '../../../shared/lib/utils';
import { useSessionContext } from '../../../features/authenticate';

import '@daouoffice/design/dist/template/profilecard.css';

export function ProfileCard() {
  const { me: userData } = useSessionContext();

  if (!userData) {
    return null;
  }
  const userName = userData.name ?? 'no-name';
  const randomColor = getRandomColor(userData.id);

  return (
    <ProfileCardPrimitives.Root>
      <ProfileCardPrimitives.Content>
        <ProfileCardPrimitives.Trigger>
          <div className="avatar_medium has_tooltip">
            <Avatar
              size={36}
              alt={userName}
              src={userData.profileImageUrl}
              backgroundColor={randomColor}
            />
            <span className="tips bottom">{userName}</span>
          </div>
        </ProfileCardPrimitives.Trigger>
        <ProfileCardPrimitives.Card
          profileInfo={{
            name: userName,
            gradeName: userData.gradeName ?? 'no-grade',
            departmentNames: [userData.departmentInfo.name ?? 'no-department'],
            email: userData.email ?? 'no-email',
            color: randomColor,
          }}
        />
      </ProfileCardPrimitives.Content>
    </ProfileCardPrimitives.Root>
  );
}
