import { useSuspenseQuery } from '@tanstack/react-query';
import * as React from 'react';
import * as companyGroupQuery from '../apis/company-group-query';
import { CompanyInfo } from '../types';

export interface IValuesContext {
  selectedAppCode: null | string;
  selectedCompany: CompanyInfo | null;
  companyList: CompanyInfo[];
}

export interface IActionsContext {
  setSelectedAppCode: React.Dispatch<React.SetStateAction<null | string>>;
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyInfo | null>>;
}

export const ValuesContext = React.createContext<IValuesContext>({
  selectedAppCode: null,
  selectedCompany: { companyId: -1, companyName: '', companyUuid: '' },
  companyList: [],
});

export const ActionsContext = React.createContext<IActionsContext>({
  setSelectedAppCode: () => {},
  setSelectedCompany: () => {},
});

export function Provider({ children }: React.PropsWithChildren) {
  const [selectedAppCode, setSelectedAppCode] = React.useState<null | string>(
    null,
  );
  const [selectedCompany, setSelectedCompany] =
    React.useState<CompanyInfo | null>(null);
  const { data: companyList, error } = useSuspenseQuery({
    queryKey: [companyGroupQuery.QUERY_KEY],
    queryFn: () => companyGroupQuery.getCompanyList(),
  });

  const valuesContext: IValuesContext = {
    selectedAppCode,
    selectedCompany,
    companyList,
  };

  const actionsContext: IActionsContext = {
    setSelectedAppCode,
    setSelectedCompany,
  };

  if (error) {
    console.error(error);
  }

  return (
    <ValuesContext.Provider value={valuesContext}>
      <ActionsContext.Provider value={actionsContext}>
        {children}
      </ActionsContext.Provider>
    </ValuesContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return { ...values, ...actions };
};
