import { TFunction } from 'i18next';
import { NodeType } from './types';

export const getNodeName = (
  key: NodeType,
  t: TFunction<'component', undefined>,
) => {
  const classNames: Record<NodeType, string> = {
    company: '',
    user: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.user',
    ),
    department: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.department',
    ),
    subdepartment: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.department',
    ),
    duty: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.duty',
    ),
    position: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.position',
    ),
    grade: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.grade',
    ),
    usergroup: t(
      'globalconfig.multiCompanyManagement.appShare.classManagement.category.userGroup',
    ),
  };

  return classNames[key];
};
