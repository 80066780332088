import clsx from 'clsx';
import { ThemeInfo } from '../../types';
import { ThemeGraphic } from './theme-graphic';

export interface Props {
  themeInfo: ThemeInfo;
  defaultChecked: boolean;
  onChange: (themeInfo: ThemeInfo) => void;
}

export function ThemePreviewItem({
  defaultChecked,
  themeInfo,
  onChange,
}: Props) {
  return (
    <button
      className={clsx(
        'flex flex-col w-[222px] rounded-lg border border-solid',
        {
          'border-black': defaultChecked,
          'border-[#E8E8E8]': !defaultChecked,
        },
      )}
      onClick={() => onChange(themeInfo)}
    >
      <div className="flex items-center w-full justify-center px-4 py-4 rounded-t-lg bg-[#E8E8E8]">
        <ThemeGraphic themeColor={themeInfo.themeColor} />
      </div>
      <div className="flex items-center w-full px-4 py-3 rounded-b-lg bg-[#F8F8F8]">
        <label className="flex items-center gap-2">
          <input
            type="radio"
            checked={defaultChecked}
            onChange={(e) => {
              e.target.checked && onChange(themeInfo);
            }}
          />
          <span>{themeInfo.title}</span>
        </label>
      </div>
    </button>
  );
}

export default ThemePreviewItem;
