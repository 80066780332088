import { Switch } from '@daouoffice/ui';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { ReactElement, useEffect, useState } from 'react';
import * as enableApprovalBetweenSitesMutation from '../../../apis/enable-approval-between-sites-mutation';
import * as enableApprovalBetweenSitesQuery from '../../../apis/enable-approval-between-sites-query';

export function ApprovalContents() {
  const queryClient = useQueryClient();
  const { alert, confirm } = useMessageDialog();
  const { t } = useTranslation('component');
  const [status, setStatus] = useState(false);
  const isExistApprovalBetweenSites = true;
  const { data: enableApprovalBetweenSites, error } = useSuspenseQuery({
    queryKey: [enableApprovalBetweenSitesQuery.QUERY_KEY],
    queryFn: () =>
      enableApprovalBetweenSitesQuery.getApprovalBetweenSitesStatus(status),
  });
  const enableMutation = useMutation({
    mutationKey: [enableApprovalBetweenSitesMutation.MUTATION_KEY],
    mutationFn: (status: boolean) =>
      enableApprovalBetweenSitesMutation.enableApprovalBetweenSitesStatus(
        status,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [enableApprovalBetweenSitesQuery.QUERY_KEY],
      }),
  });

  const messageDialogBlock: (
    title: string,
    contents: string,
  ) => ReactElement = (title: string, contents: string) => {
    return (
      <div className="size-full px-[24px]">
        <p className="text-[#1A1A1A] text-[20px] font-[500]">{title}</p>
        <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
          {contents}
        </p>
      </div>
    );
  };

  const onChangeHandler = (isEnabled: boolean) => {
    if (isEnabled) {
      void confirm(
        messageDialogBlock(
          t(
            'globalconfig.multiCompanyManagement.appShare.approval.dialog.enabled.title',
          ),
          t(
            'globalconfig.multiCompanyManagement.appShare.approval.dialog.enabled.contents',
          ),
        ),
      )
        .then((result) => {
          result && setStatus(isEnabled);
        })
        .catch(console.error);
    } else {
      if (isExistApprovalBetweenSites) {
        void confirm(
          messageDialogBlock(
            t(
              'globalconfig.multiCompanyManagement.appShare.approval.dialog.denied.title',
            ),
            t(
              'globalconfig.multiCompanyManagement.appShare.approval.dialog.denied.contents',
            ),
          ),
        )
          .then((result) => {
            result && setStatus(isEnabled);
          })
          .catch(console.error);
      } else {
        void alert(
          messageDialogBlock(
            t(
              'globalconfig.multiCompanyManagement.appShare.approval.dialog.blocked.title',
            ),
            t(
              'globalconfig.multiCompanyManagement.appShare.approval.dialog.blocked.contents',
            ),
          ),
        ).catch(console.error);
      }
    }
  };

  useEffect(() => {
    enableMutation.mutate(status);
  }, [status]);

  if (error) {
    <></>;
  }

  return (
    <div className="flex py-6 justify-between">
      <span className="text-elipsis overflow-hidden text-[#363636] text-[16px] font-normal leading-[24px] -tracking-[0.32px]">
        {t(
          'globalconfig.multiCompanyManagement.appShare.approval.enableBetwwenSites',
        )}
      </span>
      <Switch
        id="enableApprovalBetweenSites"
        checked={enableApprovalBetweenSites}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default ApprovalContents;
