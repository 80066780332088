import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { CalendarShareInfo, Direction, ShareInfo } from './types';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  QUERY_KEY,
  getCalenderShareList,
} from './apis/get-calendar-share-list';
import { useAuthContext } from '../../../../../../../../lib/auth/client';
import { getNodeName } from './utils';
import ShareConfigDialogButton from './share-config-dialog-button';
import { useActionsContext, useValuesContext } from '../../provider';

export function CalendarContents() {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const queryClient = useQueryClient();

  const { companyList } = useValuesContext();
  const { setSelectedCompany } = useActionsContext();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [direction, setDirection] = useState<Direction>('asc');
  const [selectedContents, setSelectedContents] = useState<CalendarShareInfo[]>(
    [],
  );

  const [shareConfigDialogOpenState, setShareConfigDialogOpenState] =
    useState<boolean>(false);
  const [displayCalendarShareList, setDisplayCalendarShareList] = useState<
    CalendarShareInfo[]
  >([]);
  const [detailAppId, setDetailAppId] = useState<number | null>(null);

  const { me } = useAuthContext();
  if (!me) {
    return;
  }

  const { data: calendarShareList, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getCalenderShareList(me.companyGroupId),
  });

  useEffect(() => {
    setPage(0);
  }, [size]);

  const totalPage = Math.ceil(calendarShareList.length / size);

  useEffect(() => {
    const sortedList = [...calendarShareList].sort((a, b) => {
      return direction === 'asc'
        ? a.companyName.localeCompare(b.companyName)
        : b.companyName.localeCompare(a.companyName);
    });
    const startIndex = page * size;
    const endIndex = startIndex + size;
    setDisplayCalendarShareList(sortedList.slice(startIndex, endIndex));
  }, [calendarShareList, page, size, direction]);

  const SortChevron = () => {
    if (direction === 'asc')
      return <ChevronUpIcon className="inline ml-[8px] size-[16px]" />;
    else return <ChevronDownIcon className="inline ml-[8px] size-[16px]" />;
  };

  const columnDefs: ColumnDef<CalendarShareInfo>[] = [
    {
      id: 'companyName',
      accessorKey: 'companyName',
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => handleClickSort()}
        >
          {t('globalconfig.multiCompanyManagement.appShare.calendar.siteName')}
          <SortChevron />
        </button>
      ),
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <button className="flex items-center px-[--Space-Small]">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.calendarName',
          )}
        </button>
      ),
    },
    {
      id: 'shares',
      accessorKey: 'shares',
      header: () => (
        <button className="flex items-center px-[--Space-Small]">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.shareTarget',
          )}
        </button>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as ShareInfo[];
        return (
          <div className="flex flex-col justify-center py-2">
            {shareTargetList.map((item) => {
              const shareTarget = item.nodes[0];
              return (
                <p key={item.id}>
                  {shareTarget?.nodeType === 'company'
                    ? shareTarget?.nodeCompanyName
                    : `${getNodeName(shareTarget?.nodeType!, t)}: ${shareTarget?.nodeCompanyName}-${shareTarget?.nodeValue}`}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      id: 'permission',
      accessorKey: 'shares',
      header: () => (
        <button className="flex items-center px-[--Space-Small]">
          {t(
            'globalconfig.multiCompanyManagement.appShare.calendar.userPermission',
          )}
        </button>
      ),
      cell: (cell) => {
        const shareTargetList = cell.getValue() as ShareInfo[];
        return (
          <div className="flex flex-col justify-center py-2">
            {shareTargetList.map((item) => {
              return (
                <p key={item.id}>
                  {item.nodes[0]?.actions === 'read'
                    ? t(
                        'globalconfig.multiCompanyManagement.appShare.calendar.permission.read',
                      )
                    : t(
                        'globalconfig.multiCompanyManagement.appShare.calendar.permission.readWrite',
                      )}
                </p>
              );
            })}
          </div>
        );
      },
    },
  ];

  const handleClickSort = () => {
    setDirection(direction === 'asc' ? 'desc' : 'asc');
  };

  // Todo : 삭제 confirm 문구 수정
  const handleClickDelete = async () => {
    if (selectedContents && selectedContents.length > 0) {
      if (
        await confirm(
          <div>
            <h2 className="pb-[8px]">
              {t(
                'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.title',
              )}
            </h2>
            <p>
              {t(
                'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.description1',
              )}
            </p>
            <p>
              {t(
                'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.description2',
              )}
            </p>
          </div>,
        )
      ) {
        // Todo : 삭제 api 호출
      }
    }
  };

  const handleClickRow = (index: number) => {
    if (displayCalendarShareList?.[index]) {
      setSelectedCompany({
        companyId: displayCalendarShareList[index]?.companyId!,
        companyName: displayCalendarShareList[index]?.companyName!,
        // Todo : 데이터매핑이 제대로 이루어지지 않아 임시방편
        companyUuid: companyList.find(
          (item) =>
            item.companyName === displayCalendarShareList[index]?.companyName,
        )?.companyUuid!,
      });
      setDetailAppId(displayCalendarShareList[index]?.id!);
      setShareConfigDialogOpenState(true);
    }
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > AppShare > Calendar : ',
      error,
    );
  }

  return (
    <TablePrimitives.Root<CalendarShareInfo>
      className="mt-[24px]"
      columnDefs={columnDefs}
      contents={displayCalendarShareList ?? []}
      selectable
      onSelectContents={setSelectedContents}
      onClickRow={(index) => handleClickRow(index)}
    >
      <div className="flex mb-[16px]">
        <div className="spacer flex-grow" />
        <div className="flex justify-center items-center">
          <ShareConfigDialogButton
            calendarShareList={calendarShareList}
            shareConfigDialogOpenState={shareConfigDialogOpenState}
            detailAppId={detailAppId}
            onChangeDetailAppId={setDetailAppId}
            onChangeShareConfigDialogOpenState={setShareConfigDialogOpenState}
          />
          <Button
            className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded hover:bg-[#f2f2f2]"
            onClick={handleClickDelete}
          >
            <TrashIcon width={24} height={24} />
            {t('globalconfig.multiCompanyManagement.appShare.calendar.delete')}
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[52px]"
            tableSize={size}
            options={[10, 20, 30, 40]}
            onChangeTableSize={setSize}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        emptyNotice={
          <div className="w-full h-[80px] flex items-center justify-center ">
            <span className="text-[--color-text-level3] text-[14px] font-[400]">
              {t(
                'globalconfig.multiCompanyManagement.appShare.calendar.noList',
              )}
            </span>
          </div>
        }
      ></TablePrimitives.Contents>
      <TablePrimitives.Pagination
        className="mt-[16px]"
        currentPage={page}
        totalPage={totalPage ?? 0}
        onNextPage={setPage}
        onPreviousPage={setPage}
        onClickPage={setPage}
        onFirstPage={() => setPage(0)}
        onLastPage={() => setPage(totalPage - 1 ?? 0)}
      />
    </TablePrimitives.Root>
  );
}

export default CalendarContents;
