'use client';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Suspense, useEffect, useRef, useState } from 'react';
import { AdminTableClient } from './AdminTable/AdminTableClient';
import { Provider } from './Context';
import AuthListClient from './authorization/manager/AuthList/AuthListClient';
import EditAppListClient from './authorization/manager/EditAppList/EditAppListClient';
import { SubTabGroup } from './authorization/manager/SubTabGroup';
import { SubTabType } from './authorization/manager/types';

export default function Index() {
  const [type, setType] = useState<SubTabType>('perApps');
  const { t } = useTranslation('component');
  const perAppsRef = useRef<HTMLButtonElement>(null);
  const perManagersRef = useRef<HTMLButtonElement>(null);
  const [tabWidth, setTabWidth] = useState<number>(0);
  const [translateX, setTranslateX] = useState<number>(0);

  const handleOnclickSubTab = (selectedType: SubTabType) => {
    setType(selectedType);
  };

  useEffect(() => {
    const appsWidth = perAppsRef.current?.offsetWidth ?? 0;
    const managersWidth = perManagersRef.current?.offsetWidth ?? 0;

    if (type === 'perApps') {
      setTabWidth(appsWidth);
      setTranslateX(0);
    } else if (type === 'perManagers') {
      setTabWidth(managersWidth);
      setTranslateX(appsWidth + 16);
    }
  }, [type]);

  return (
    <Provider title={t('globalconfig.secureManagement.adminManagement.master')}>
      <SubTabGroup.Root>
        <div className="content_page">
          <div className="wrap_tabs line_type !gap-2 !mx-0 relative">
            <div className="dop_tabs flex">
              <SubTabGroup.SubTab
                ref={perAppsRef}
                type={'perApps'}
                title={t(
                  'globalconfig.secureManagement.adminManagement.setPerApps',
                )}
                onClickTab={handleOnclickSubTab}
              />
              <SubTabGroup.SubTab
                ref={perManagersRef}
                type={'perManagers'}
                title={t(
                  'globalconfig.secureManagement.adminManagement.setPerManagers.tab',
                )}
                onClickTab={handleOnclickSubTab}
              />
            </div>

            <div
              className={`absolute bottom-0 h-[2px] bg-black transition-transform duration-300`}
              style={{
                width: `${tabWidth}px`,
                transform: `translateX(${translateX}px)`,
              }}
            />
          </div>

          <div className="border border-solid border-[#E6E7EA] p-10 mt-[42px] rounded-xl">
            <h2 className="text-[22px] font-medium -tracking-[1.32px]">
              {type === 'perApps'
                ? t('globalconfig.secureManagement.adminManagement.setPerApps')
                : t(
                    'globalconfig.secureManagement.adminManagement.setPerManagers',
                  )}
            </h2>
            {type === 'perManagers' ? (
              <div className="flex items-stretch mt-6 gap-6">
                <AdminTableClient type="management" />
                <AuthListClient />
              </div>
            ) : type === 'perApps' ? (
              <div className="flex items-stretch mt-6 gap-6">
                <Suspense
                  fallback={
                    <div className="w-[300px] border border-solid border-[#DBE0EB] rounded-3xl flex justify-center items-center">
                      Loading...
                    </div>
                  }
                >
                  <EditAppListClient />
                </Suspense>
                <AdminTableClient type="app" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </SubTabGroup.Root>
    </Provider>
  );
}
