/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { memo, useEffect, useRef, useState } from 'react';
import { useDextEditorContext } from './provider';
import { DEXT5_CONFIG_HANDLER_URL } from './contants';
import { getDext5ConfigInitXML } from './utils';

export interface Props {
  id: string;
  content?: string;
  className?: string;
}

export function DextEditor({ id, content: initContent, className }: Props) {
  const { isEditorLoaded, setIsEditorLoaded } = useDextEditorContext();
  const [isLoading, setIsLoading] = useState(false);

  const loaded = useRef(false);

  useEffect(() => {
    if (!(window as any).dext_editor_loaded_event) {
      (window as any).dext_editor_loaded_event = function (editor: any) {
        if (editor.ID === id) {
          DEXT5.setHtmlContentsEw(initContent ?? '', id);
          setIsEditorLoaded(true);
          setIsLoading(false);
        }
      };
    }
    if (DEXT5 && !isLoading && !isEditorLoaded && !loaded.current) {
      loaded.current = true;
      setIsLoading(true);
      DEXT5.config.Width = '100%';

      DEXT5.config.InitXml = getDext5ConfigInitXML();
      DEXT5.config.HandlerUrl = DEXT5_CONFIG_HANDLER_URL;
      DEXT5.config.EditorHolder = id;
      new Dext5editor(id);
    }

    return () => {
      if (!isLoading && DEXT5) {
        DEXT5.destroy(id, true);
      }
      delete (window as any).dext_editor_loaded_event;
    };
  }, [isLoading, isEditorLoaded]);

  return <div id={id} className={className}></div>;
}

export const MemoizedDextEditor = memo(DextEditor);
