import { usePopupAnnouncementContext } from './context';
import PopupAnnouncementForm from './popup-announcement-form';
import PopupAnnouncementList from './popup-announcement-list';
import styles from './popup-announcement.module.css';

import { POPUP_DICTIONARY } from './constants';

import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ArrowLeftIcon } from '@dop-ui/icons/react/dop/24';
import { IconButton } from '@dop-ui/react/shared/ui/button';

function PopupAnnouncementContents() {
  const { t } = useTranslation('component');

  const { path, setPath } = usePopupAnnouncementContext();

  const handleSwitchToMain = () => {
    setPath('main');
  };

  const title = t(`${POPUP_DICTIONARY}${path === 'form' ? '.form' : ''}`);

  return (
    <div className={styles.Contents}>
      <p className={styles.CardTitle}>
        {path === 'form' && (
          <IconButton icon={ArrowLeftIcon} onClick={handleSwitchToMain} />
        )}
        {title}
      </p>
      {path === 'main' && <PopupAnnouncementList />}

      {path === 'form' && <PopupAnnouncementForm />}
    </div>
  );
}

export default PopupAnnouncementContents;
