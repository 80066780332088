import { useState } from 'react';

import useNotificationMutations from '../hooks/use-notification-mutations';
import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';

import NotificationList from '../list';
import { Button } from '../../../shared/ui/button';
import * as Popover from '../../../shared/ui/popover';
import InfinityNotificationList from '../infinity-list';

import { CheckIcon } from '@dop-ui/icons/react/dop/16';
import {
  ChevronRightIcon,
  DeleteIcon,
  EllipsisVerticalIcon,
  SettingsIcon,
} from '@dop-ui/icons/react/dop/24';
import styles from './noti-layer.module.css';

type Menu = 'all' | 'unread';
export function NotificationLayer() {
  const { t } = useTranslation();

  const [selectedMenu, setSelectedMenu] = useState<Menu>('all');

  const handleOnChangeMenu = (menu: Menu) => {
    if (menu === selectedMenu) return;
    setSelectedMenu(menu);
  };

  const { deleteAll, readAll } = useNotificationMutations();

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <p className={styles.Title}>{t('notification.title')}</p>
        {selectedMenu === 'unread' && (
          <Button className={styles.ButtonWithIcon} onClick={() => readAll()}>
            <CheckIcon size={16} />
            {t('notification.readAll')}
          </Button>
        )}

        <Popover.Root>
          <Popover.Trigger className="ml-auto">
            <EllipsisVerticalIcon size={24} />
          </Popover.Trigger>
          <Popover.Content isPortal align="end" className={styles.LayerMenu}>
            {/* TODO: Button v2 업데이트 후 변경 필요 */}
            <Button
              variant="ghost"
              className="!justify-start"
              size="md"
              autosize="full"
              onClick={() => void deleteAll()}
            >
              <DeleteIcon size={16} />
              {t('notification.deleteAll')}
            </Button>
            <Button
              variant="ghost"
              className="!justify-start"
              size="md"
              autosize="full"
            >
              <SettingsIcon size={16} />
              {t('notification.setting')}
            </Button>
          </Popover.Content>
        </Popover.Root>
      </div>
      <div className={styles.Contents}>
        <div className={styles.MenuList}>
          {/* TODO: Button v2 업데이트 후 변경 필요 */}
          <Button
            size="md"
            shape="round"
            variant="outline"
            colorset={selectedMenu === 'all' ? 'level1' : 'level2'}
            onClick={() => handleOnChangeMenu('all')}
          >
            {t('notification.all')}
          </Button>
          <Button
            size="md"
            shape="round"
            variant="outline"
            colorset={selectedMenu === 'unread' ? 'level1' : 'level2'}
            onClick={() => handleOnChangeMenu('unread')}
          >
            {t('notification.unRead')}
          </Button>
        </div>
        <InfinityNotificationList menu={selectedMenu} />
      </div>
      <div className={styles.Footer}>
        {/* TODO: Button v2 업데이트 후 변경 필요 */}
        <Button variant="ghost" size="md" onClick={() => setDialogOpen(true)}>
          {t('notification.readMore')}
          <ChevronRightIcon size={16} />
        </Button>
      </div>
      <NotificationList open={dialogOpen} onOpenChange={setDialogOpen} />
    </div>
  );
}

export default NotificationLayer;
