import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { COMPANY_LIST_API } from '../../constants';
import { CompanyInfo } from './../types';
import { getCompanyGroupId } from './company-group-id-query';

interface CompanyListResponse {
  data: {
    companyList: CompanyInfo[];
  };
}

export const QUERY_KEY = 'companyGroupQuery';

export async function getCompanyList() {
  try {
    const companyGroupId = await getCompanyGroupId();
    const { data } = (await (
      await fetch(`${COMPANY_LIST_API}/${companyGroupId}`)
    ).json()) as CompanyListResponse;
    return data.companyList;
  } catch (error) {
    console.error(error);
    return [];
  }
}
