import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table/index';
import { Direction } from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { BoardInfo, ShareNodeInfo } from '../../../types';
import { ShareAreaConfig } from '../../share-area-config';
import { ShareConfigDialog } from '../../share-config-dialog';

const defaultBoardList: BoardInfo[] = Array.from(
  { length: 10 },
  (_, index: number) => {
    return {
      id: index,
      name: `게시판 ${index + 1}`,
      siteName: `사이트 ${index + 1}`,
      shares: Array.from({ length: 3 }, (_, index: number) => {
        return {
          id: index,
          nodeValue: `대상 ${index + 1}`,
          actions: index % 2 == 0 ? 'read,write' : 'read',
        } as ShareNodeInfo;
      }),
    };
  },
);

export function BoardContents() {
  const { t } = useTranslation('component');
  const [shareConfigDialogOpenState, setShareConfigDialogOpenState] =
    useState<boolean>(false);
  const [shareNodeList, setSharedNodeList] = useState<ShareNodeInfo[]>([]);
  const [, setSelectedBoardId] = useState<number>();
  const [boardList] = useState<BoardInfo[]>(defaultBoardList);
  const [pageOffet, setPageOffset] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedSharedList, setSelectedSharedList] = useState<BoardInfo[]>();
  const [direction, setDirection] = useState<Direction>();
  const [orderType, setOrderType] = useState<string>('siteName');

  const onSaveHandler = () => {
    // TODO: selectedBoardId와 shareNodeList를 ShareRequestModel로 감싸 서버로 전송해야함.
  };

  const onCancelHandler = () => {};

  const onEditHandler = (index: number) => {
    console.log('clicked index:', index);
    if (boardList?.[index]) {
      setSharedNodeList(boardList[index]?.shares);
      setSelectedBoardId(boardList[index]?.id);
      setShareConfigDialogOpenState(true);
    }
  };

  const onDeleteHandler = () =>
    selectedSharedList &&
    selectedSharedList?.length > 0 &&
    alert('Delete Button Clicked!');

  const shareConfigDialogBlock = () => (
    <ShareConfigDialog
      trigger={
        <Button
          className="flex items-center gap-1 p-1"
          onClick={() => setShareConfigDialogOpenState(true)}
        >
          <PlusIcon className="size-6 stroke-[#363636]" />
          <span className="text-[14px] font-normal leading-[150%] -tracking-[0.28px]">
            {t(
              'globalconfig.multiCompanyManagement.appShare.board.addButton.title',
            )}
          </span>
        </Button>
      }
      openState={shareConfigDialogOpenState}
      onOpenStateChange={(openState) =>
        setShareConfigDialogOpenState(openState)
      }
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
    >
      <ShareAreaConfig<BoardInfo>
        shares={shareNodeList}
        appChildList={boardList}
        onChange={(board) => setSelectedBoardId(board.id)}
        onChangeShares={(newShares) => {
          setSharedNodeList(newShares);
        }}
      />
    </ShareConfigDialog>
  );

  const onSortChangeHandler = (direction: Direction, value: string) => {
    setDirection(direction);
    setOrderType(value);
  };

  const columnDefs: ColumnDef<BoardInfo>[] = [
    TablePrimitives.columnDefGenerator<BoardInfo>(
      'siteName',
      t('globalconfig.multiCompanyManagement.appShare.board.table.siteName'),
      'siteName',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    TablePrimitives.columnDefGenerator<BoardInfo>(
      'name',
      t('globalconfig.multiCompanyManagement.appShare.board.table.boardName'),
    ),
    {
      accessorKey: 'shares-node-value',
      id: 'shares-node-value',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.multiCompanyManagement.appShare.board.table.sharedObject',
          )}
        </div>
      ),
      cell: (value: CellContext<BoardInfo, unknown>) => (
        <div
          key={`shares-nodeValue-${value.row.original.id}`}
          className="flex flex-col gap-1 py-[12px]"
        >
          {value.row.original.shares.map((sharedInfo) => (
            <span
              key={`nodeValue-${sharedInfo.id}`}
              className="text-elipsis overflow-hidden text-[14px] font-normal leading-[14px] -tracking-[0.28px]"
            >
              {sharedInfo.nodeValue}
            </span>
          ))}
        </div>
      ),
    },
    {
      accessorKey: 'shares-permission',
      id: 'shares-permission',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.multiCompanyManagement.appShare.board.table.permission',
          )}
        </div>
      ),
      cell: (value: CellContext<BoardInfo, unknown>) => (
        <div
          key={`shares-permission-${value.row.original.id}`}
          className="flex flex-col gap-1 py-[12px]"
        >
          {value.row.original.shares.map((sharedInfo) => (
            <span
              key={`permission-${sharedInfo.id}`}
              className="text-elipsis overflow-hidden text-[14px] font-normal leading-[14px] -tracking-[0.28px]"
            >
              {sharedInfo.actions === 'read'
                ? t(
                    'globalconfig.multiCompanyManagement.appShare.board.permission.read',
                  )
                : t(
                    'globalconfig.multiCompanyManagement.appShare.board.permission.readWrite',
                  )}
            </span>
          ))}
        </div>
      ),
    },
  ];

  return (
    <TablePrimitives.Root<BoardInfo>
      contents={defaultBoardList}
      columnDefs={columnDefs}
      onSelectContents={setSelectedSharedList}
      onClickRow={(index) => onEditHandler(index)}
      selectable
    >
      <div className="flex flex-wrap justify-between">
        <div className="flex items-center">
          <div className="h-3 border-l-1 border-[#D8D8D8] px-1" />
        </div>
        <div className="flex justify-end items-center gap-2">
          {shareConfigDialogBlock()}
          <Button
            className="flex items-center gap-1 p-1"
            // TODO: 데이터 매핑 후, 삭제 API 연동 필요.
            onClick={onDeleteHandler}
          >
            <TrashIcon className="size-6 stroke-[#363636]" />
            <span className="text-[14px] font-normal leading-[150%] -tracking-[0.28px]">
              {t(
                'globalconfig.multiCompanyManagement.appShare.board.deleteButton.title',
              )}
            </span>
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[48px]"
            tableSize={pageOffet}
            options={[10, 20, 30]}
            onChangeTableSize={setPageOffset}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        className="text-center mt-4"
        emptyNotice={
          <div className="w-full py-[60px] flex items-center justify-center ">
            <span className="w-full text-center font-normal leading-norml=al text-[#888] text-ellipsis">
              {t('globalconfig.multiCompanyManagement.appShare.table.noData')}
            </span>
          </div>
        }
      />
      <div className="flex items-center justify-center gap-2">
        {
          <TablePrimitives.Pagination
            className="mt-[16px]"
            currentPage={currentPage}
            totalPage={100}
            onNextPage={setCurrentPage}
            onPreviousPage={setCurrentPage}
            onClickPage={setCurrentPage}
            onFirstPage={() => setCurrentPage(0)}
            onLastPage={() => setCurrentPage(99)}
          />
        }
      </div>
    </TablePrimitives.Root>
  );
}

export default BoardContents;
