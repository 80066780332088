import { ChangeEvent, useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { usePopupAnnouncementContext } from '../context';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import {
  Templates,
  useToastMessage,
} from '@dop-ui/react/features/toast-message';
import SquirecleAvatar from '@dop-ui/react/shared/ui/avatar/squirecle-avatar';
import * as getPopupListQueries from '../apis/get-popup-announcement-list';
import { deletePopupAnnouncement } from '../apis/delete-popup-announcement';

import { Button } from '@dop-ui/react/shared/ui/button';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';

import {
  AdditionIcon,
  DeleteIcon,
  SearchIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { AscendIcon, DescendIcon, SortIcon } from '@dop-ui/icons/react/dop/16';
import { DEFAULT_OFFSET, POPUP_DICTIONARY } from '../constants';
import styles from './popup-announcement-list.module.css';
import { getSortedDayOfWeeks } from '../utils';
import type {
  IPopupAnnouncement,
  IPopupAnnouncementForm,
  UseOptions,
  Week,
} from '../types';

function PopupAnnouncementList() {
  const [page, setPage] = useState(0);
  const [pageOffset, setPageOffset] = useState(DEFAULT_OFFSET);
  const { info } = useToastMessage();
  const { confirm } = useMessageDialog();
  const { setPath, setUpdateIdx } = usePopupAnnouncementContext();

  const { t } = useTranslation('component');

  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const [searchType, setSearchType] = useState('title');
  const searchTypeOptions = ['title', 'creator'];
  const [isUse, setIsUse] = useState<UseOptions>('all');
  const useOptions = ['all', 'use', 'notUse'];
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: 'asc',
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [keyword]);

  const [selectedPopup, setSelectedPopup] = useState<IPopupAnnouncement[]>([]);

  useEffect(() => {
    setUpdateIdx(null);
  }, []);

  const { data, error } = useSuspenseQuery({
    queryKey: [
      getPopupListQueries.QUERY_KEY,
      page,
      pageOffset,
      searchType,
      debouncedKeyword,
      sortInfo,
      isUse,
    ],
    queryFn: () =>
      getPopupListQueries.getPopupAnnouncementList({
        page,
        size: pageOffset,
        searchType,
        keyword: debouncedKeyword,
        ...sortInfo,
        isUse,
      }),
  });

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: deletePopupAnnouncement,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getPopupListQueries.QUERY_KEY],
      });
      info(<Templates.Basic description={t(`${POPUP_DICTIONARY}.ondelete`)} />);
    },
    onError: (e) => {
      console.log('popup announcement > delete 에러 발생', e);
    },
  });

  const handleOnChangeSearchType = (e: ChangeEvent<HTMLSelectElement>) => {
    setSearchType(e.target.value);
  };

  const handleOnChangeIsUse = (e: ChangeEvent<HTMLSelectElement>) => {
    setIsUse(e.target.value as UseOptions);
  };

  const handleOnChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    if (page !== 0) setPage(0);
  };

  const getFormatDate = (date: Date) => {
    return formatDate({
      date: date,
      format: 'YYYY-MM-DD',
    });
  };

  const deleteConfirm = (
    <div className={styles.Confirm}>
      <p className={styles.ConfirmTitle}>
        {t(`${POPUP_DICTIONARY}.deleteConfirm.title`)}
      </p>
      <p className={styles.ConfirmContent}>
        {t(`${POPUP_DICTIONARY}.deleteConfirm.content`)}
      </p>
    </div>
  );

  const handleOnDelete = async () => {
    if (selectedPopup.length === 0) {
      alert(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.list.delete.emptyMessage`)}
        />,
      );
      return;
    } else {
      if (await confirm(deleteConfirm)) {
        deleteMutation.mutate({
          popupAnnouncementIds: selectedPopup.map((popup) => popup.id),
        });
        setSelectedPopup([]);
      }
    }
  };

  const navigateToCreate = () => {
    setPath('form');
  };

  const handleOnClickRow = (index: number) => {
    const target = data.elements[index];
    if (!target) return;
    setUpdateIdx(target.id);
    navigateToCreate();
  };

  const getDayOfWeek = (weeks: Week[]) => {
    const sortedDayOfWeeks = getSortedDayOfWeeks(weeks);
    const dayOfWeeks = sortedDayOfWeeks.map(
      (week) =>
        `${t(`${POPUP_DICTIONARY}.${week}`)}${t(`${POPUP_DICTIONARY}.dayOfWeek`)}`,
    );
    return dayOfWeeks.join(', ');
  };

  const getPeriodPreview = (data: IPopupAnnouncementForm) => {
    const {
      noticePeriodType,
      dayOfWeeks,
      repeatValue,
      targetWeek,
      targetDay,
      targetDayOfWeek,
    } = data;

    switch (noticePeriodType) {
      case 'DAILY_REPEAT':
        return repeatValue === 1
          ? `${t(`${POPUP_DICTIONARY}.everyDay`)} ${t(`${POPUP_DICTIONARY}.repeat`)}`
          : `${repeatValue}${t(`${POPUP_DICTIONARY}.perDays`)} ${t(`${POPUP_DICTIONARY}.repeat`)}`;
      case 'WEEKLY_REPEAT':
        return `${repeatValue}${t(`${POPUP_DICTIONARY}.perWeeks`)} ${getDayOfWeek(dayOfWeeks ?? [])}`;
      case 'MONTHLY_DAY_REPEAT':
        return `${repeatValue}${t(`${POPUP_DICTIONARY}.perMonths`)} ${targetDay}${t(`${POPUP_DICTIONARY}.day`)}`;
      case 'MONTHLY_WEEKDAY_REPEAT':
        return `${repeatValue}${t(`${POPUP_DICTIONARY}.perMonths`)} ${targetWeek}${t(`${POPUP_DICTIONARY}.nth`)} ${t(`${POPUP_DICTIONARY}.${targetDayOfWeek}`)}${t(`${POPUP_DICTIONARY}.dayOfWeek`)}`;
    }
  };

  const handleOnChangeSort = (column: string) => {
    setSortInfo((prev) => {
      const { sortBy } = prev;
      if (sortBy === column) {
        return {
          ...prev,
          sortDirection: prev.sortDirection === 'asc' ? 'desc' : 'asc',
        };
      } else {
        return {
          sortBy: column,
          sortDirection: 'asc',
        };
      }
    });
  };

  const sortIcon = (column: string) => {
    let icon = null;

    if (column !== sortInfo.sortBy) {
      icon = <SortIcon />;
    } else {
      if (sortInfo.sortDirection === 'asc') {
        icon = <AscendIcon />;
      } else {
        icon = <DescendIcon />;
      }
    }
    return <Button onClick={() => handleOnChangeSort(column)}>{icon}</Button>;
  };

  const columnDefs: ColumnDef<IPopupAnnouncement>[] = [
    {
      id: 'title',
      accessorFn: (data: IPopupAnnouncement) => data.title,
      header: () => (
        <div className={styles.TableHeader}>
          <p>{t(`${POPUP_DICTIONARY}.title`)}</p>
          {sortIcon('title')}
        </div>
      ),
      cell: (data) => {
        return <p className={styles.TableCell}>{data.getValue() as string}</p>;
      },
    },
    {
      id: 'period',
      accessorFn: (data) => data,
      header: () => (
        <p className={styles.TableHeader}>{t(`${POPUP_DICTIONARY}.period`)}</p>
      ),
      cell: (data) => {
        const announcement = data.getValue() as IPopupAnnouncementForm;
        if (announcement.noticePeriodType === 'SPECIFIC_DATE')
          return (
            <p
              className={styles.TableCell}
            >{`${getFormatDate(announcement.startDate)} ~ ${getFormatDate(announcement.endDate)}`}</p>
          );
        else {
          return (
            <p className={styles.TableCell}>{getPeriodPreview(announcement)}</p>
          );
        }
      },
    },
    {
      id: 'device',
      accessorFn: (data) => data.device,
      header: () => (
        <p className={styles.TableHeader}>{t(`${POPUP_DICTIONARY}.device`)}</p>
      ),
      cell: (data) => {
        return (
          <p className={styles.TableCell}>
            {t(`${POPUP_DICTIONARY}.device.${data.getValue() as string}`)}
          </p>
        );
      },
    },
    {
      id: 'status',
      accessorFn: (data) => data.isUse,
      header: () => (
        <p className={styles.TableHeader}>{t(`${POPUP_DICTIONARY}.status`)}</p>
      ),
      cell: (data) => {
        return (
          <div className={styles.TableCell}>
            <p className={data.getValue() ? styles.UseTag : styles.NotUseTag}>
              {t(`${POPUP_DICTIONARY}.${data.getValue() ? 'use' : 'notUse'}`)}
            </p>
          </div>
        );
      },
    },
    {
      id: 'creator',
      accessorFn: (data) => data,
      header: () => (
        <div className={styles.TableHeader}>
          {t(`${POPUP_DICTIONARY}.creator`)}
          {sortIcon('user')}
        </div>
      ),
      cell: (data) => {
        const announcement = data.getValue() as IPopupAnnouncement;
        return (
          <div className={styles.TableCell}>
            <SquirecleAvatar
              size={24}
              alt={announcement.creatorName}
              src={announcement.creatorProfileImage}
            />
            <p className={styles.TableCell}>{`${announcement.creatorName}`}</p>
          </div>
        );
      },
    },
    {
      id: 'createdDate',
      accessorFn: (data) => data.createdDate,
      header: () => (
        <div className={styles.TableHeader}>
          <p>{t(`${POPUP_DICTIONARY}.createdDate`)}</p>
          {sortIcon('createdAt')}
        </div>
      ),

      cell: (data) => {
        return (
          <p className={styles.TableCell}>
            {getFormatDate(data.getValue() as Date)}
          </p>
        );
      },
    },
  ];

  if (!data || error) {
    return <></>;
  }

  return (
    <TablePrimitives.Root<IPopupAnnouncement>
      selectable
      columnDefs={columnDefs}
      contents={data.elements || []}
      onSelectContents={setSelectedPopup}
      className="flex flex-col gap-6"
      onClickRow={handleOnClickRow}
    >
      <div className={styles.Header}>
        <div className={styles.HeaderFilters}>
          <div className={styles.SearchBar}>
            <SearchIcon size={20} />
            <select
              className={styles.SearchTypeSelect}
              defaultValue={searchType}
              onChange={handleOnChangeSearchType}
            >
              {searchTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {t(`${POPUP_DICTIONARY}.${option}`)}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={keyword}
              onChange={handleOnChangeKeyword}
              className={styles.SearchInput}
            />
          </div>
          <div className={styles.Divider} />
          <select
            className={styles.StatusSelect}
            defaultValue={isUse}
            onChange={handleOnChangeIsUse}
          >
            {useOptions.map((option) => (
              <option key={option} value={option}>
                {t(`${POPUP_DICTIONARY}.status.${option}`)}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.ToolButtons}>
          <Button
            className={styles.IconButton}
            onClick={() => navigateToCreate()}
          >
            <AdditionIcon size={24} />
            <p> {t(`${POPUP_DICTIONARY}.add`)} </p>
          </Button>
          <Button
            className={styles.IconButton}
            onClick={() => void handleOnDelete()}
          >
            <DeleteIcon size={24} />
            <p> {t(`${POPUP_DICTIONARY}.delete`)} </p>
          </Button>

          <TablePrimitives.SizeSelector
            className={styles.SizeSelector}
            onChangeTableSize={setPageOffset}
            options={[10, 20, 30]}
          />
        </div>
      </div>

      <TablePrimitives.Contents
        emptyNotice={
          <p className={styles.EmptyMessage}>
            {t(`${POPUP_DICTIONARY}.empty`)}
          </p>
        }
      />
      <TablePrimitives.Pagination
        currentPage={page}
        onNextPage={setPage}
        onPreviousPage={setPage}
        onClickPage={setPage}
        onFirstPage={() => setPage(0)}
        onLastPage={() => {
          setPage(data.page.totalPages);
        }}
        totalPage={1}
      />
    </TablePrimitives.Root>
  );
}

export default PopupAnnouncementList;
