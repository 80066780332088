import { useDebounce } from '@daouoffice/ui';
import * as Icon24 from '@daouoffice/ui/lib/icons/dop/24';
import { IconButton } from '@dop-ui/react/shared/ui/button';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

export interface Props {
  title: string;
  placeholder: string;
  value?: string;
  onValueChange: (value: string) => void;
}

export function SearchInput({
  title,
  placeholder,
  value,
  onValueChange,
}: Props) {
  const [text, setText] = useState(value ? value : '');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setText(value ?? '');
  }, [value]);

  const searchKeywordDebouncer = useDebounce((value: string) => {
    console.log('value changed');

    onValueChange(value);
  }, 250);

  const onChangeHandler = (value: string) => {
    setText(value);
    searchKeywordDebouncer(value);
  };

  return (
    <div
      className={clsx(
        'flex items-center w-[240px] ps-4 bg-[#EEF1F7] rounded-[8px]',
        {
          focused: isFocused || (value && value !== ''),
        },
      )}
    >
      <div className="input_search flex items-center gap-2 h-10">
        <MagnifyingGlassIcon className="size-5 stroke-[#AAAAAA]" />
        <input
          className="placeholder-[#AAAAAA] text-black body_medium regular"
          id="test_search"
          type="search"
          title={title}
          placeholder={placeholder}
          value={text}
          onChange={(e) => {
            onChangeHandler(e.target.value);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        {text.length > 0 && (
          <IconButton
            className="clear bg-transparent"
            tabIndex={-1}
            onClick={() => onChangeHandler('')}
            icon={() => <Icon24.SearchClearIcon size={20} />}
          />
        )}
      </div>
    </div>
  );
}
