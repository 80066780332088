import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { COMPANY, DEPARTMENT, MEMBER } from './constants';
import { NodeProps } from './interface';
import { getNodeId } from './Util';

export const treesAtom = atom<NodeProps[]>([]);
export const searchTreesAtom = atom<NodeProps[]>([]);
export const selectedNodesAtom = atom<NodeProps[]>([]);
export const lastSelectedNodeAtom = atomWithDefault<NodeProps | null>(
  () => null,
);

export const addChilds = (trees: NodeProps[], node: NodeProps) => {
  const update = (item: NodeProps): NodeProps => {
    if (getNodeId(node) === getNodeId(item)) {
      return {
        ...item,
        childrenList:
          node.childrenList?.map((subItem) => {
            return {
              ...subItem,
              selected: item.selected,
            };
          }) ?? [],
      };
    } else if (item.childrenList?.length) {
      return {
        ...item,
        childrenList: item.childrenList?.map(update),
      };
    }
    return item;
  };

  return trees.map(update);
};

const updateNode = (node: NodeProps, selected: boolean): NodeProps => {
  const updatedNode = { ...node, selected };
  if (node.childrenList) {
    updatedNode.childrenList =
      node.childrenList?.map((child) => updateNode(child, selected)) ?? [];
  }
  return updatedNode;
};

export const updateTrees = (
  trees: NodeProps[],
  node: NodeProps,
): NodeProps[] => {
  const updatedTrees = trees.map((item) => {
    if (getNodeId(node) === getNodeId(item)) {
      return updateNode(node, node.selected ?? false);
    } else if (item.childrenList) {
      return {
        ...item,
        childrenList: updateTrees(item.childrenList, node),
        active: false,
      };
    }
    return { ...item, active: false };
  });

  const updateParents = (nodes: NodeProps[], nodeId: string): NodeProps[] => {
    const updatedNodes = nodes.map((node) => {
      if (node.childrenList) {
        const updatedChildren = updateParents(node.childrenList, nodeId);
        const allSelected =
          updatedChildren.length > 0
            ? updatedChildren.every((child) => child.selected)
            : node.selected;
        return {
          ...node,
          selected: allSelected,
          childrenList: updatedChildren,
        };
      }
      return node;
    });
    return updatedNodes;
  };

  return updateParents(updatedTrees, getNodeId(node));
};

export const getDisplaySelectedNodes = (nodes: NodeProps[], type: string) => {
  const selectedNodes: NodeProps[] = [];

  const isDepartmentOrCompany = (node: NodeProps) =>
    node.nodeType === DEPARTMENT || node.nodeType === COMPANY;
  const isMember = (node: NodeProps) => node.nodeType === MEMBER;

  const traverseTree = (node: NodeProps): void => {
    const hasChild = node.childrenList && node.childrenList.length > 0;
    const allChildrenSelected =
      hasChild && node.childrenList?.every((child) => child.selected);

    const shouldSelectNode =
      (type === DEPARTMENT &&
        (node.selected || allChildrenSelected) &&
        isDepartmentOrCompany(node)) ||
      (type === MEMBER && node.selected && isMember(node));

    if (shouldSelectNode) {
      selectedNodes.push({ ...node });
    } else if (isDepartmentOrCompany(node) || type === DEPARTMENT) {
      node.childrenList?.forEach(traverseTree);
    }
  };

  nodes.forEach(traverseTree);
  return selectedNodes;
};

export const getSelectedNodes = (nodes: NodeProps[], type: string = '') => {
  const selectedNodes: NodeProps[] = [];

  if (type !== MEMBER && type !== DEPARTMENT) type = '';

  const traverseTree = (node: NodeProps): void => {
    if (node.selected && node.nodeType === type) {
      selectedNodes.push({ ...node });
    }

    node.childrenList?.forEach((child) => traverseTree(child));
  };

  nodes.forEach((node) => traverseTree(node));

  return selectedNodes;
};

export const updateSelectedNodes = (nodes: NodeProps[], node: NodeProps) => {
  const index = nodes.findIndex((item) => getNodeId(node) === getNodeId(item));
  if (node.selected) {
    nodes.push(node);
  } else {
    nodes.splice(index, 1);
  }
  return nodes;
};

export const syncNodes = (searchNodes: NodeProps[], trees: NodeProps[]) => {
  const treeMap = new Map<string, NodeProps>();

  const buildTreeMap = (node: NodeProps) => {
    treeMap.set(getNodeId(node), node);
    if (node.childrenList) {
      node.childrenList.forEach(buildTreeMap);
    }
  };

  trees.forEach(buildTreeMap);

  const syncNode = (searchNode: NodeProps): NodeProps => {
    const originNode = treeMap.get(getNodeId(searchNode));

    if (originNode) {
      const childrenList = searchNode.childrenList || originNode.childrenList;
      return {
        ...originNode,
        childrenList: childrenList?.map(syncNode),
      };
    } else {
      return searchNode;
    }
  };

  return searchNodes.map(syncNode);
};
