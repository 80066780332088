import { APP_SHARE_COMPANY_CALENDER_LIST_URL } from '../constants';
import {
  mockedCompanyCalendarList,
  mockedCompanyCalendarList402,
  mockedCompanyCalendarList452,
  mockedCompanyCalendarList502,
} from '../mocks';
import { CalendarResponse, CompanyCalendarInfo } from '../types';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.AppShare.CompanyCalendarList';

export async function getCompanyCalenderList(
  companyId: number,
): Promise<CompanyCalendarInfo[]> {
  try {
    return new Promise((resolve) => {
      setTimeout(() => {
        var mockedList = mockedCompanyCalendarList402;
        if (companyId === 402) {
          mockedList = mockedCompanyCalendarList402;
        } else if (companyId === 452) {
          mockedList = mockedCompanyCalendarList452;
        } else if (companyId === 502) {
          mockedList = mockedCompanyCalendarList502;
        } else {
          mockedList = mockedCompanyCalendarList;
        }
        const resData = mockedList as CalendarResponse<CompanyCalendarInfo>;
        resolve(resData.data);
      }, 200);
    });

    // const response = await fetch(
    // APP_SHARE_COMPANY_CALENDER_LIST_URL + '/' + companyId,
    //   {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-Referer-Info': window.location.host,
    //     },
    //   },
    // );

    // if (!response.ok) {
    //   throw new Error('getCompanyCalenderList 오류 발생.');
    // }

    // const resData = (await response.json()) as CalendarResponse<CompanyCalendarInfo>;

    // return resData.data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
