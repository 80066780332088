import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { isContentNeedsSaveAtom } from '../../../../../../store/isContentNeedsSaveAtom';
import * as deviceAccessMutation from '../../../api/device-access-mutation';
import * as deviceAccessQuery from '../../../api/device-access-query';
import { AccessRoleInfo, AppLockInfo, DeviceAccessType } from '../../../types';
import { useActionsContext, useValuesContext } from '../../context';
import { DetailConfig } from './detail-config';

export interface Props {
  data: AppLockInfo;
}

export function DeviceAccessConfig({ data: defaultData }: Props) {
  const { info } = useToastMessage();
  const setNeedSave = useSetAtom(isContentNeedsSaveAtom);
  const [appLockInfo, setAppLockInfo] = useState(defaultData);
  const queryClient = useQueryClient();
  const { t } = useTranslation('component');
  const { isEditMode, selectedAppId } = useValuesContext();
  const { setEditMode } = useActionsContext();
  const updateMutation = useMutation({
    mutationKey: [deviceAccessMutation.MUTATION_KEY],
    mutationFn: () =>
      deviceAccessMutation.updateDevice(selectedAppId, appLockInfo),
    onSuccess: async () => {
      info(
        t(
          checkSavedContentsTheSame()
            ? 'globalconfig.secureManagement.appManagement.toast.noChanges.title'
            : 'globalconfig.secureManagement.appManagement.toast.save.title',
        ),
      );
      setEditMode(false);
      await queryClient.invalidateQueries({
        queryKey: [deviceAccessQuery.QUERY_KEY],
      });
    },
  });

  const checkSavedContentsTheSame = () => appLockInfo === defaultData;

  useEffect(() => {
    setAppLockInfo(defaultData);
  }, [defaultData, selectedAppId]);

  const onCancelHandler = () => {
    setEditMode(false);
    setAppLockInfo(defaultData);
  };

  const onSaveHandler = () => {
    updateMutation.mutate();
  };

  useEffect(() => {
    setNeedSave(isEditMode);
  }, [isEditMode, setNeedSave]);

  const appLockInfoChangeHandler = (
    deviceType: DeviceAccessType,
    newAccessRoleInfo: AccessRoleInfo,
  ) => {
    setEditMode(true);
    if (deviceType === 'WEB') {
      setAppLockInfo((prev) => {
        return {
          ...prev,
          webAccessRoleInfo: newAccessRoleInfo,
        };
      });
    } else if (deviceType === 'MOBILE') {
      setAppLockInfo((prev) => {
        return {
          ...prev,
          mobileAccessRoleInfo: newAccessRoleInfo,
        };
      });
    } else {
      setAppLockInfo((prev) => {
        return {
          ...prev,
          messengerAccessRoleInfo: newAccessRoleInfo,
        };
      });
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3">
        <div className="flex gap-6 justify-between items-start">
          <h2 className="w-[180px] text-[#11] text-[16px] text-left text-ellipsis font-medium -tracking-[0.96px]">
            {t(
              'globalconfig.secureManagement.appManagement.device.config.title',
            )}
          </h2>
          <span className="text-[#AAA] text-[14px] -tracking-[0.28px] font-normal">
            {t(
              'globalconfig.secureManagement.appManagement.device.config.description',
            )}
          </span>
        </div>
        <div className="border border-dashed rounded-lg border-[#E5E5E5] w-full p-6">
          <div className="flex flex-col justify-between gap-">
            {/* WEB */}
            {appLockInfo?.webActivated && (
              <DetailConfig
                deviceType="WEB"
                data={appLockInfo.webAccessRoleInfo}
                onChange={(accessRoleInfo: AccessRoleInfo) =>
                  appLockInfoChangeHandler('WEB', accessRoleInfo)
                }
              />
            )}
            {/* MOBILE */}
            {appLockInfo?.mobileActivated && (
              <DetailConfig
                deviceType="MOBILE"
                data={appLockInfo.mobileAccessRoleInfo}
                onChange={(accessRoleInfo: AccessRoleInfo) =>
                  appLockInfoChangeHandler('MOBILE', accessRoleInfo)
                }
              />
            )}
            {/* MESSENGER */}
            {appLockInfo?.messengerActivated && (
              <DetailConfig
                deviceType="MESSENGER"
                data={appLockInfo.messengerAccessRoleInfo}
                onChange={(accessRoleInfo: AccessRoleInfo) =>
                  appLockInfoChangeHandler('MESSENGER', accessRoleInfo)
                }
              />
            )}
          </div>
        </div>
        <div className="flex justify-center items-center gap-2 py-4 mt-6">
          <Button
            colorset="level2"
            variant="outline"
            shape="rect"
            size="md"
            onClick={onCancelHandler}
          >
            {t(
              'globalconfig.secureManagement.appManagement.deviceaccess.cancel.title',
            )}
          </Button>
          <Button
            shape="rect"
            variant="solid"
            colorset="level1"
            size="md"
            onClick={onSaveHandler}
          >
            {t(
              'globalconfig.secureManagement.appManagement.deviceaccess.save.title',
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeviceAccessConfig;
