import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { useQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import OrgChart from '../../../../../../OrgChart';
import { NodeProps } from '../../../../../../OrgChart/interface';
import * as classSubSelectionQuery from '../../apis/class-sub-selection-query';
import {
  ActionType,
  ClassInfo,
  ClassType,
  CompanyInfo,
  NodeShareType,
  ScopeType,
  ShareNodeInfo,
} from '../../types';
import { SelectConfig } from './select-config';

export interface Props {
  scopeType: ScopeType;
  companyList: CompanyInfo[];
  defaultPermitState?: boolean;
  onClickAddButton?: (newShare: ShareNodeInfo) => void;
}

/**
 * @description ClassSiteConfig : 대상 사이트와 대상 클래스를 추가해주는 역할하는 컴포넌트
 */
export function ClassSiteConfig({
  scopeType,
  companyList,
  defaultPermitState,
  onClickAddButton,
}: Props) {
  const { t } = useTranslation('component');
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(
    companyList?.[0] ?? null,
  );
  const [permitState, setPermitState] = useState<boolean | undefined>(
    defaultPermitState,
  );
  const [orgChartOpenState, setOrgChartOpenState] = useState(false);
  const classList: ClassInfo[] = [
    {
      type: 'USER',
      name: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.user',
      ),
    },
    {
      name: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.department',
      ),
      type: 'DEPARTMENT',
    },
    {
      name: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.position',
      ),
      type: 'POSITION',
    },
    {
      name: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.grade',
      ),
      type: 'GRADE',
    },
    {
      name: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.duty',
      ),
      type: 'DUTY',
    },
    {
      name: t(
        'globalconfig.multiCompanyManagement.appShare.classManagement.category.userGroup',
      ),
      type: 'USER_GROUP',
    },
  ];
  const [classType, setClassType] = useState<ClassType>(
    classList?.[0]?.type ?? 'USER',
  );
  const {
    data: subClassList,
    error,
    refetch,
  } = useQuery({
    queryKey: [classSubSelectionQuery.QUERY_KEY, { classType }],
    queryFn: async () => {
      if (companyInfo?.companyId === undefined) return [];

      return await classSubSelectionQuery.getSubSelectionList(
        classType,
        companyInfo?.companyUuid,
      );
    },
  });
  const [subClassInfo, setSubClassInfo] = useState(subClassList?.[0]);

  const addButtonClickHandler = () => {
    if (classType !== 'USER' && classType !== 'DEPARTMENT') {
      if (
        classType !== 'COMPANY' &&
        (!subClassList || (subClassList && subClassList?.length <= 0))
      )
        return;
      sharesHandler(subClassInfo?.id, subClassInfo?.name);
    } else {
      setOrgChartOpenState(true);
    }
  };

  const MemberOrgChart = () => {
    const onNodeClickHandler = (node: NodeProps) => {
      sharesHandler(node.id, node.name);
      setOrgChartOpenState(false);
    };

    return (
      <Dialog.Root open={orgChartOpenState} onOpenChange={setOrgChartOpenState}>
        <Dialog.Content>
          <OrgChart
            companyId={companyInfo?.companyId ?? undefined}
            useFooter={false}
            type={classType === 'DEPARTMENT' ? 'DEPARTMENT' : 'USER'}
            useMultiSelect={false}
            useHeader={false}
            onNodeClick={onNodeClickHandler}
            style={{
              width: '360px',
              height: '550px',
              overflowY: 'scroll',
              padding: '24px 0px',
              borderRadius: '16px',
              border: '1px solid #f2f2f2',
              boxShadow: 'none',
              position: 'static',
            }}
          />
        </Dialog.Content>
      </Dialog.Root>
    );
  };

  const sharesHandler = (id?: number, value?: string) =>
    companyInfo &&
    onClickAddButton &&
    onClickAddButton({
      nodeId: classType === 'COMPANY' ? companyInfo.companyId : id ?? 0,
      nodeType:
        classType !== 'USER_GROUP'
          ? (classType.toLowerCase() as NodeShareType)
          : ('usergroup' as NodeShareType),
      nodeCompanyName: companyInfo.companyName,
      nodeCompanyId: companyInfo.companyId,
      nodeValue:
        classType === 'COMPANY' ? companyInfo.companyName : value ?? '',
      actions: (permitState ? 'read,write' : 'read') as ActionType,
    });

  // scopeType이 ALL일 경우, classType을 COMPANY로 설정
  useEffect(() => {
    setClassType(scopeType === 'ALL' ? 'COMPANY' : 'USER');
  }, [scopeType]);

  // 대상사이트, 대상클래스 변경될 때마다 각 해당하는 subClassList refetch
  useEffect(() => {
    refetch().catch(console.error);
  }, [classType, companyInfo, refetch]);

  // subClassList가 변경될 때마다 첫번째 subClassInfo로 설정
  useEffect(() => {
    subClassList &&
      subClassList?.length > 0 &&
      setSubClassInfo(subClassList?.[0]);
  }, [subClassList]);

  // 대상사이트 list가 변경될 때마다 선택된 대상 사이트 정보 업데이트
  useEffect(() => {
    setCompanyInfo(companyList?.[0] ?? null);
  }, [companyList]);

  if (error) {
    return <></>;
  }

  return (
    <div className="grow flex flex-col gap-[6px]">
      <SelectConfig
        permitStatus={permitState}
        onPermitChange={(newState) => setPermitState(newState)}
        withAddButton={scopeType === 'ALL'}
        onClickAddButton={addButtonClickHandler}
        title={t(
          'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.targetSite',
        )}
      >
        <select
          className="grow px-3 h-[40px] text-[14px] text-[#363636] font-normal rounded-[8px] border border-solid border-[#D8D8D8]"
          value={companyInfo?.companyId}
          onChange={(e) => {
            setCompanyInfo(
              companyList.find(
                (companyInfo) =>
                  companyInfo.companyId === Number(e.target.value),
              ) ?? null,
            );
          }}
        >
          {companyList.map((companyInfo) => (
            <option
              key={`option-${companyInfo.companyId}`}
              value={companyInfo.companyId}
            >
              {companyInfo.companyName}
            </option>
          ))}
        </select>
      </SelectConfig>
      {scopeType === 'PART' && (
        <SelectConfig
          permitStatus={permitState}
          onPermitChange={(newState) => setPermitState(newState)}
          onClickAddButton={addButtonClickHandler}
          title={t(
            'globalconfig.multiCompanyManagement.appShare.dialog.field.shareConfig.targetClass',
          )}
          withAddButton
        >
          <select
            className="grow px-3 w-[108px] h-[40px] text-[14px] text-ellipsis text-[#363636] font-normal rounded-[8px] border border-solid border-[#D8D8D8]"
            value={classType}
            onChange={(e) => setClassType(e.target.value as ClassType)}
          >
            {classList.map((classInfo) => (
              <option key={`option-${classInfo.type}`} value={classInfo.type}>
                {classInfo.name}
              </option>
            ))}
          </select>
          {classType !== 'USER' && classType !== 'DEPARTMENT' && (
            <select
              className={clsx(
                'grow px-3 w-[108px] h-[40px] text-[14px] text-[#363636] text-ellipsis font-normal rounded-[8px] border border-solid border-[#D8D8D8]',
                {
                  'bg-[#F2F2F2] border-none text-[#808080]':
                    !subClassList || subClassList?.length <= 0,
                },
              )}
              value={subClassInfo?.id}
              onChange={(e) =>
                setSubClassInfo(() =>
                  subClassList?.find(
                    (subClassInfo) =>
                      subClassInfo?.id === Number(e.target.value),
                  ),
                )
              }
            >
              {subClassList && subClassList?.length > 0 ? (
                subClassList?.map((subClassInfo) => (
                  <option
                    key={`option-${subClassInfo.id}`}
                    value={subClassInfo.id}
                  >
                    {subClassInfo.name}
                  </option>
                ))
              ) : (
                <option key="option-no-data" value="no-data">
                  {t(
                    'globalconfig.multiCompanyManagement.appShare.classManagement.category.subClass.noData',
                  )}
                </option>
              )}
            </select>
          )}
        </SelectConfig>
      )}
      <MemberOrgChart />
    </div>
  );
}

export default ClassSiteConfig;
