import { useEffect, useState } from 'react';
import { ShareNodeInfo } from '../../../types';
import ShareConfigDialog from '../../share-config-dialog';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import ShareAreaConfig from '../../share-area-config';
import { CalendarShareInfo, CompanyCalendarInfo } from './types';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useValuesContext } from '../../provider';
import { useQuery } from '@tanstack/react-query';
import {
  QUERY_KEY,
  getCompanyCalenderList,
} from './apis/get-company-calandar-list';

interface Props {
  calendarShareList: CalendarShareInfo[];
  shareConfigDialogOpenState: boolean;
  detailAppId: number | null;
  onChangeDetailAppId: (id: number | null) => void;
  onChangeShareConfigDialogOpenState: (state: boolean) => void;
}

export function ShareConfigDialogButton({
  calendarShareList,
  shareConfigDialogOpenState,
  detailAppId,
  onChangeDetailAppId,
  onChangeShareConfigDialogOpenState,
}: Props) {
  const { t } = useTranslation('component');
  const { selectedCompany } = useValuesContext();

  const [shareNodeList, setSharedNodeList] = useState<ShareNodeInfo[]>([]);
  const [selectedCalendar, setSelectedCalendar] = useState<{
    id: number;
    name: string;
  }>({ id: 0, name: '' });

  // Todo : 현재 사이트(selectedCompany)에 알맞은 캘린더 가져오기(api 호출)
  // const [calendarList, setCalendarList] = useState<CalendarShareInfo[]>([]);
  const { data: calendarList, error } = useQuery({
    queryKey: [QUERY_KEY, selectedCompany?.companyId],
    queryFn: async () =>
      await getCompanyCalenderList(selectedCompany?.companyId!),
    enabled: !!selectedCompany?.companyId,
  });

  useEffect(() => {
    const sharedTargetList = calendarShareList.filter(
      (item) =>
        item.companyId === selectedCompany?.companyId &&
        item.id === selectedCalendar?.id,
    )[0]?.shares;

    if (sharedTargetList?.length === 0) {
      return;
    }

    const sharedTargetNodeList = sharedTargetList
      ?.map((item) => item.nodes.map((item) => item as ShareNodeInfo))
      .flat();

    setSharedNodeList(sharedTargetNodeList!);
  }, [selectedCalendar]);

  const handleSaveHandler = () => {
    // TODO: selectedBoardId와 shareNodeList를 ShareRequestModel로 감싸 서버로 전송해야함.
  };

  const handleCancelHandler = () => {};

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > AppShare > Calendar > Dialog: ',
      error,
    );
  }

  return (
    <ShareConfigDialog
      trigger={
        <Button
          className="flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded hover:bg-[#f2f2f2]"
          onClick={() => {
            onChangeDetailAppId(null);
            onChangeShareConfigDialogOpenState(true);
          }}
        >
          <PlusIcon width={24} height={24} />
          {t('globalconfig.multiCompanyManagement.appShare.calendar.add')}
        </Button>
      }
      openState={shareConfigDialogOpenState}
      onOpenStateChange={(openState) =>
        onChangeShareConfigDialogOpenState(openState)
      }
      onSave={handleSaveHandler}
      onCancel={handleCancelHandler}
    >
      <ShareAreaConfig<CompanyCalendarInfo>
        shares={shareNodeList}
        appChildList={calendarList}
        defaultAppId={detailAppId}
        onChange={(calendar) => {
          setSelectedCalendar({ id: calendar.id, name: calendar.name });
        }}
        onChangeShares={(newShares) => {
          setSharedNodeList(newShares);
        }}
      />
    </ShareConfigDialog>
  );
}

export default ShareConfigDialogButton;
