import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ArrowLeftIcon as ArrowLeftHeaderIcon } from '@heroicons/react/24/outline';
import ProfileTable from './profile-table';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  QUERY_KEY,
  getCustomProfileList,
} from '../../apis/get-custom-profile-list';
export function CustomProfileList() {
  const { t } = useTranslation('component');
  const stacker = useStackMethod();

  const { data: customProfileList, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getCustomProfileList(),
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > BasicManagement > ProfileConfig > CustomProfile: ',
      error,
    );
  }

  const onClickBackButton = async () => {
    stacker.pop();
  };

  return (
    <div className="flex flex-col flex-wrap gap-[32px] p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex flex-col gap-[8px]">
        <div className="flex items-center">
          <Button
            className="!size-[24px] mr-[8px]"
            size="none"
            onClick={() => void onClickBackButton()}
          >
            <ArrowLeftHeaderIcon className="" />
          </Button>
          <p className="text-[#333] text-[18px] font-medium -tracking-[1.2px]">
            {t(
              'globalconfig.basicManagement.profileManagement.customProfileItemManagement',
            )}
          </p>
        </div>
        <p className="py-[4px] text-[13px] text-[#888]">
          {t(
            'globalconfig.basicManagement.profileManagement.customProfileItemManagement.description',
          )}
        </p>
      </div>
      <ProfileTable customProfileList={customProfileList} />
    </div>
  );
}

export default CustomProfileList;
