import { fetch } from '../../../../../../../../lib/fetch/client';

export interface ErrorResponseBody {
  message: string;
  code: string;
}

export async function postMasterDataInfo(userId: number) {
  try {
    const pathUrl = `/api/portal/admin/master/${userId}`;

    const resp = await fetch(pathUrl, {
      method: 'POST',
    });

    if (!resp.ok) {
      throw new Error(((await resp.json()) as ErrorResponseBody)?.message);
    }
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      return e.message;
    }
    return String(e);
  }
}

export async function putTableDataInfo(
  companyAppId: string,
  userIds: number[],
): Promise<string | undefined> {
  try {
    const pathUrl = `/api/portal/admin/app-manager/app/${companyAppId}`;

    const resp = await fetch(pathUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: userIds,
      }),
    });

    if (!resp.ok) {
      throw new Error(((await resp.json()) as ErrorResponseBody)?.message);
    }

    return undefined;
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      return e.message;
    }
    return String(e);
  }
}
