import { fetch } from '../../../../../../lib/fetch/client';
import { AppContentsInfo, Option, Page, ResponseBody, Status } from '../types';
import { searchParamGenerator } from '../utils/searchParamGenerator';
import { MOCK_URL_PATH } from './constants';

interface ReportData {
  id?: number;
  status?: Status;
  attachSize?: number;
  name?: string;
}

export interface ResponseReportBody {
  page?: Page;
  data?: ReportData[];
}

export async function reportDataQuery(deptId: number, options?: Option) {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/report/department/${deptId}/folders?${searchParamGenerator(options)}`;
    const res = (await fetch(FETCH_URL)).json();
    const data = (await res) as ResponseReportBody;
    return adapter(data);
  } catch (error) {
    console.error(error);
  }
}

function adapter(res: ResponseReportBody): ResponseBody {
  return {
    page: res?.page,
    data: res?.data?.map((report) => {
      return {
        id: report?.id,
        name: report?.name ?? '',
        status: report?.status,
        storageUsage: report.attachSize,
      } as AppContentsInfo;
    }),
  };
}
