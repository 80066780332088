import type { MenuListResponse } from '../types';

export const QUERY_KEY = 'QueryKeys.Notification.MenuList';

export async function getMenuList(): Promise<MenuListResponse> {
  const response = await fetch(`/api/portal/common/notification/user/app`);

  const { data } = (await response.json()) as { data: MenuListResponse };

  return data;
}
