import { useInfiniteQuery } from '@tanstack/react-query';
import * as getNotificationListQueries from '../apis/get-notification-list';
import { NOTIFICATION_SLICE_SIZE } from '../constants';

interface Props {
  menu: string;
}
export default function useInfinityNotificationList({ menu }: Props) {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [getNotificationListQueries.QUERY_KEY, menu],
      queryFn: ({
        pageParam = {
          previousLastId: -1,
          sliceSize: NOTIFICATION_SLICE_SIZE,
          appCode: menu,
        },
      }) => getNotificationListQueries.getNotificationList(pageParam),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.slice.hasNext) {
          return {
            previousLastId: lastPage.slice.lastId,
            sliceSize: lastPage.slice.sliceSize,
            appCode: menu,
          };
        }

        return undefined;
      },
      initialPageParam: {
        previousLastId: -1,
        sliceSize: NOTIFICATION_SLICE_SIZE,
        appCode: menu,
      },
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
}
