import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { LOGO_THEME_CONFIG_API } from '../../constants';
import { LogoThemeConfigInfo } from '../types';
import { BaseResponse } from './../../types';

export const MUTATION_KEY =
  'GlobalConfig.BasicManagement.LogoThemeManagement.LogoThemeMutation';

export async function putLogoThemeConfig(req: LogoThemeConfigInfo) {
  try {
    const res = await fetch(LOGO_THEME_CONFIG_API, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
      body: JSON.stringify(req),
    });
    const { data } = (await res.json()) as BaseResponse<LogoThemeConfigInfo>;
    return data;
  } catch (error) {
    console.error(error);
    return {} as LogoThemeConfigInfo;
  }
}
