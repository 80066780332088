import { fetch } from '../../../../../../lib/fetch/client';
import { AppOverView } from '../types';
import { MOCK_URL_PATH } from './constants';

export const QUERY_KEY = 'deptEntireDataQuery';

export interface ResponseDeptEntireData {
  deptId?: number;
  createdAt?: string;
  deletedAt?: string;
  attachSize?: number;
  appList?: AppOverView[];
}

interface ResponseBody {
  code?: number;
  message?: string;
  data?: ResponseDeptEntireData;
}

export async function deptEntireDataQuery(deptId?: number) {
  try {
    if (!deptId) return {};
    const FETCH_URL = `${MOCK_URL_PATH}/deletedepts/${deptId}`;
    const res = (await fetch(FETCH_URL)).json() as ResponseBody;
    return res;
  } catch (error) {
    console.error(error);
  }
}
