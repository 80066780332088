import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ClassListManager } from '../../../../../components/class-list-manager';
import { CHAT_APP_CODE, ORG_CHART_APP_CODE } from '../../../../constants';
import {
  AccessRoleInfo,
  AccessType,
  DeviceAccessType,
} from '../../../../types';
import { useValuesContext } from '../../../context';

export interface Props {
  deviceType: DeviceAccessType;
  data?: AccessRoleInfo;
  onChange?: (newAccessRoleInfo: AccessRoleInfo) => void;
}

export function DetailConfig({ deviceType, data, onChange }: Props) {
  const { t } = useTranslation('component');
  const { selectedAppCode } = useValuesContext();
  const title =
    deviceType === 'WEB'
      ? t('globalconfig.secureManagement.appManagement.deviceAccessType.web')
      : deviceType === 'MOBILE'
        ? t(
            'globalconfig.secureManagement.appManagement.deviceAccessType.mobile',
          )
        : t(
            'globalconfig.secureManagement.appManagement.deviceAccessType.messenger',
          );
  const radioItems =
    selectedAppCode === ORG_CHART_APP_CODE
      ? [
          {
            id: 'allow',
            value: 'ALLOW',
            label: t(
              'globalconfig.secureManagement.appManagement.radio.orgChart.allow',
            ),
          },
          {
            id: 'ALLOW_SEARCH',
            value: 'ALLOW_SEARCH',
            label: t(
              'globalconfig.secureManagement.appManagement.radio.orgChart.allowSearch',
            ),
          },
          {
            id: 'disallow',
            value: 'DISALLOW',
            label: t(
              'globalconfig.secureManagement.appManagement.radio.orgChart.disallow',
            ),
          },
        ]
      : [
          {
            id: 'allow',
            value: 'ALLOW',
            label: t('globalconfig.secureManagement.appManagement.radio.allow'),
          },
          {
            id: 'disallow',
            value: 'DISALLOW',
            label: t(
              'globalconfig.secureManagement.appManagement.radio.disallow',
            ),
          },
        ];

  const tooltipBlock = (companyAppCode: string, accessType: AccessType) => {
    const tooltip = (contents: string) => (
      <Tooltip.Root>
        <Tooltip.Trigger>
          <QuestionMarkCircleIcon className="size-4 stroke-[#AAAAAA]" />
        </Tooltip.Trigger>
        <Tooltip.Content className="absolute z-[1000]">
          {contents}
        </Tooltip.Content>
      </Tooltip.Root>
    );

    if (companyAppCode === ORG_CHART_APP_CODE && accessType === 'DISALLOW') {
      return tooltip(
        t('globalconfig.secureManagement.appManagement.orgChart.tooltip'),
      );
    } else if (companyAppCode === CHAT_APP_CODE && accessType === 'DISALLOW') {
      return tooltip(
        t(
          deviceType === 'MESSENGER'
            ? 'globalconfig.secureManagement.appManagement.chat.messenger.disallow.tooltip'
            : 'globalconfig.secureManagement.appManagement.chat.mobile.disallow.tooltip',
        ),
      );
    }

    return <></>;
  };

  return (
    <div className="flex w-full m-4">
      <span className="w-[180px] leading-[14px] text-left -tracking-[0.28px] text-[14px] font-normal text-ellipsis overflow-hidden">
        {title}
      </span>
      <div className="w-full flex flex-col gap-[12px]">
        <div className="flex items-center gap-6 z-[999]">
          {radioItems.map((item) => (
            <label
              className="flex items-center"
              key={`${deviceType}-radio-${item.id}`}
              id={`${deviceType}-radio-${item.id}`}
            >
              <input
                type="radio"
                value={item.value}
                checked={data?.appAccessLimitType === item.value}
                onChange={(e) =>
                  onChange?.({
                    appAccessLimitType: e.target.value as AccessType,
                    policyIncludeRoleList: [],
                    policyExcludeRoleList: [],
                  })
                }
              />
              <span className="ms-2 font-normal text-[14px] leading-[21px] -tracking-[0.28px] text-[#363636]">
                {item.label}
              </span>
              {tooltipBlock(selectedAppCode, item.value as AccessType)}
            </label>
          ))}
        </div>
        <span className="text-[#999999] font-normal leading-[21px] -tracking-[0.28px]">
          {t(
            data?.appAccessLimitType === 'DISALLOW'
              ? 'globalconfig.secureManagement.appManagement.permitexcept.userclass.title'
              : 'globalconfig.secureManagement.appManagement.block.userclass.title',
          )}
        </span>
        <ClassListManager
          narrowMode
          classRoleInfo={data?.policyIncludeRoleList ?? []}
          onClassInfoChange={(info) =>
            onChange?.({
              appAccessLimitType: data?.appAccessLimitType ?? 'ALLOW',
              policyIncludeRoleList: info,
              policyExcludeRoleList: data?.policyExcludeRoleList ?? [],
            })
          }
          accessDeviceType={deviceType}
          accessLimitTargetType={'INCLUDE'}
        />
        <span className="text-[#999999] font-normal leading-[21px] -tracking-[0.28px]">
          {t(
            data?.appAccessLimitType !== 'DISALLOW'
              ? 'globalconfig.secureManagement.appManagement.permitexcept.userclass.title'
              : 'globalconfig.secureManagement.appManagement.block.userclass.title',
          )}
        </span>
        <ClassListManager
          narrowMode
          classRoleInfo={data?.policyExcludeRoleList ?? []}
          onClassInfoChange={(info) =>
            onChange?.({
              appAccessLimitType: data?.appAccessLimitType ?? 'ALLOW',
              policyIncludeRoleList: data?.policyIncludeRoleList ?? [],
              policyExcludeRoleList: info,
            })
          }
          accessDeviceType={deviceType}
          accessLimitTargetType={'EXCLUDE'}
        />
      </div>
    </div>
  );
}

export default DetailConfig;
