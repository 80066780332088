import { CUSTOM_PROFILE_CARD_API } from '../../constants';
import fetch from '@dop-ui/react/shared/lib/fetch/client';

export async function deleteCustomProfile(profileId: number) {
  try {
    const response = await fetch(`${CUSTOM_PROFILE_CARD_API}/${profileId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    });

    if (!response.ok) {
      throw new Error('deleteCustomProfile 오류 발생.');
    }

    return response;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
}
