import { useAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Button } from '@dop-ui/react/shared/ui/button/dop-button';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { isContentNeedsSaveAtom } from '../../../../../../store/isContentNeedsSaveAtom';
import { SaveConfirmDialog } from '../../../../../../parts/components/save-confirm-dialog';
import { LinkSimpleInfo } from '../../../types';
import { NoLinkImage } from '../assets/no-link-image';
import { deleteLinkInfo, getLinksInfo, QUERY_KEY } from '../apis/links-setting';
import { LinkItem } from './link-item';
import { LinkConfig } from './link-config';

export function LinksContainer() {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const [isNeedsSave, setIsNeedSave] = useAtom(isContentNeedsSaveAtom);
  const [currentSelectedLinkId, setCurrentSelectedLinkId] =
    useState<number>(-1);
  const [recentSelectedIndex, setRecentSelectedIndex] = useState<number>(0);
  const [linkInfoList, setLinkInfoList] = useState<LinkSimpleInfo[]>([]);

  const toast = useToastMessage();
  const queryClient = useQueryClient();

  const { data: simpleLinkInfoList, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getLinksInfo,
  });

  const deleteLinkInfoMutation = useMutation({
    mutationFn: async (linkId: number) => deleteLinkInfo(linkId),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      toast.info(t('globalconfig.common.toastMessage.deleted'));
    },
    onError: () => {
      toast.warning(t('globalconfig.common.toastMessage.fail'));
    },
  });

  useEffect(() => {
    setLinkInfoList(simpleLinkInfoList);
  }, [simpleLinkInfoList]);

  useEffect(() => {
    if (linkInfoList.length > 0) {
      const info = linkInfoList[recentSelectedIndex];
      setCurrentSelectedLinkId(info?.linkId || -1);
    }
  }, [linkInfoList, recentSelectedIndex]);

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > EmpAppManagement > Links > error',
      error,
    );
  }

  const checkSave = async (confirmAction: () => void) => {
    if (await confirm(<SaveConfirmDialog />)) {
      setIsNeedSave(false);
      confirmAction();
    }
  };

  const addNewLink = () => {
    setRecentSelectedIndex(simpleLinkInfoList?.length || 0);
    setLinkInfoList([
      ...(simpleLinkInfoList || []),
      {
        linkId: -10,
        name: t('globalconfig.basicManagement.employeePortal.link.newLink'),
      },
    ]);
    setCurrentSelectedLinkId(-10);
    setIsNeedSave(true);
  };

  const deleteNewLink = () => {
    const info = simpleLinkInfoList?.[recentSelectedIndex - 1];
    setLinkInfoList(simpleLinkInfoList || []);
    if (info) setCurrentSelectedLinkId(info.linkId);
    setRecentSelectedIndex(
      recentSelectedIndex - 1 < 0 ? 0 : recentSelectedIndex - 1,
    );
  };

  const onClickAddLink = () => {
    if (isNeedsSave)
      void checkSave(() => {
        addNewLink();
      });
    else addNewLink();
  };

  const onClickLink = (linkId: number, index: number) => {
    if (currentSelectedLinkId === linkId) return;

    if (isNeedsSave) {
      void checkSave(() => {
        if (currentSelectedLinkId === -10) {
          deleteNewLink();
        } else {
          setCurrentSelectedLinkId(linkId);
          setRecentSelectedIndex(index);
          setIsNeedSave(false);
        }
      });
    } else {
      setCurrentSelectedLinkId(linkId);
      setRecentSelectedIndex(index);
    }
  };

  const onClickDeleteLink = (linkId: number) => {
    if (linkId === -10) {
      deleteNewLink();
      toast.info(t('globalconfig.common.toastMessage.deleted'));
      setIsNeedSave(false);
    } else {
      deleteLinkInfoMutation.mutate(linkId);
    }
  };

  return (
    <div className="flex gap-[8px]">
      <div className="flex-shrink-0 w-[260px] flex flex-col gap-[4px] pt-[8px] pr-[8px] overflow-scroll border-r border-solid border-[#D8D8D8]">
        <Button
          className="h-[40px] mb-[16px] border border-dashed border-spacing-[1px] border-[#C5C5C5] rounded-[8px]"
          autosize="full"
          onClick={onClickAddLink}
        >
          <PlusIcon className="size-[20px] mr-[8px]" />
          <p className="text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
            {t('globalconfig.basicManagement.employeePortal.link.addNew')}
          </p>
        </Button>
        {linkInfoList.length > 0 &&
          linkInfoList.map((link, index) => (
            <LinkItem
              key={link.linkId}
              linkId={link.linkId}
              linkName={link.name}
              selected={currentSelectedLinkId === link.linkId}
              onClickLink={(id) => onClickLink(id, index)}
            />
          ))}
      </div>
      {linkInfoList.length > 0 && (
        <Suspense fallback={<div>Loading...</div>}>
          <LinkConfig
            linkId={currentSelectedLinkId}
            onClickDelete={onClickDeleteLink}
          />
        </Suspense>
      )}
      {linkInfoList.length === 0 && (
        <div className="flex flex-col items-center justify-center gap-[8px] flex-grow h-full pl-[40px] py-[48px] //pl-[--Space-3XLarge]">
          <NoLinkImage />
          <p className="text-[#999999] //text-[--color-text-level3] text-[14px] font-[500]">
            {t('globalconfig.basicManagement.employeePortal.link.noLink')}
          </p>
        </div>
      )}
    </div>
  );
}
