import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Switch } from '@daouoffice/ui';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  Templates,
  useToastMessage,
} from '@dop-ui/react/features/toast-message';
import * as Dext5Editor from '@dop-ui/react/shared/ui/dext-editor';

import FormField from './components/form-field';
import FormAnnouncementPeroid from './form-announcement-period';
import FormAnnouncementTarget from './form-announcement-target';
import { createPopupAnnouncement } from '../apis/create-popup-announcement';
import { updatePopupAnnouncement } from '../apis/update-popup-announcement';
import * as getPopupAnnouncementQueries from '../apis/get-popup-announcement';
import * as getPopupAnnouncementListQueries from '../apis/get-popup-announcement-list';

import { usePopupAnnouncementContext } from '../context';
import {
  DEFAULT_POPUP_FORM,
  POPUP_DEVICES,
  POPUP_OPTIONS,
  POPUP_DICTIONARY,
} from '../constants';
import type { IPopupAnnouncementForm } from '../types';
import styles from './styles.module.css';
import { ClipIcon } from '@dop-ui/icons/react/dop/24';
import { convertToForm } from '../utils';

function PopupAnnouncementForm() {
  const editorId = 'popup-announcement-create';
  const { updateIdx, setPath, setUpdateIdx } = usePopupAnnouncementContext();
  const { t } = useTranslation('component');
  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const popupAnnouncement = useQuery({
    queryKey: [getPopupAnnouncementQueries.QUERY_KEY, updateIdx],
    queryFn: () => getPopupAnnouncementQueries.getPopupAnnouncement(updateIdx!),
    enabled: !!updateIdx,
  });

  const [popupForm, setPopupForm] = useState<IPopupAnnouncementForm>(
    updateIdx && popupAnnouncement.data
      ? convertToForm(popupAnnouncement.data)
      : DEFAULT_POPUP_FORM,
  );

  useEffect(() => {
    setPopupForm(
      updateIdx && popupAnnouncement.data
        ? convertToForm(popupAnnouncement.data)
        : DEFAULT_POPUP_FORM,
    );
  }, [updateIdx, popupAnnouncement.data]);

  const createPopupMutation = useMutation({
    mutationFn: createPopupAnnouncement,
    onSuccess: () => {
      toaster.message(
        <Templates.Basic description={t(`${POPUP_DICTIONARY}.form.onsave`)} />,
      );
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementListQueries.QUERY_KEY],
      });
      setPath('main');
    },
  });

  const updatePopupMutation = useMutation({
    mutationFn: updatePopupAnnouncement,
    onSuccess: () => {
      toaster.message(
        <Templates.Basic description={t(`${POPUP_DICTIONARY}.form.onsave`)} />,
      );
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementListQueries.QUERY_KEY],
      });
      void queryClient.invalidateQueries({
        queryKey: [getPopupAnnouncementQueries.QUERY_KEY, updateIdx],
      });
      setUpdateIdx(null);
      setPath('main');
    },
  });

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    setPopupForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnChangeIsUse = (value?: boolean) => {
    setPopupForm((prev) => ({
      ...prev,
      isUse: !!value,
    }));
  };

  const formValidate = (form: IPopupAnnouncementForm) => {
    if (form.title.length < 1 || form.title.length > 64) {
      toaster.info(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.form.titleLengthMessage`)}
        />,
      );
      return false;
    }
    if (form.content.length === 0) {
      toaster.info(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.form.emptyContentMessage`)}
        />,
      );
      return false;
    }

    if (
      form.noticePeriodType === 'WEEKLY_REPEAT' &&
      form.dayOfWeeks?.length === 0
    ) {
      toaster.info(
        <Templates.Basic
          description={t(`${POPUP_DICTIONARY}.form.emptyDayOfWeeks`)}
        />,
      );
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    setPopupForm((prev) => ({
      ...prev,
      content: DEXT5.getBodyValue(editorId) as string,
    }));
    const data = {
      ...popupForm,
      content: DEXT5.getBodyValue(editorId) as string,
    };
    if (formValidate(data)) {
      if (updateIdx) {
        updatePopupMutation.mutate({
          data,
          popupId: updateIdx,
        });
      } else {
        createPopupMutation.mutate({
          data,
        });
      }
    }
  };
  const fileUploader = useRef<HTMLInputElement>(null);
  return (
    <>
      <FormField title={t(`${POPUP_DICTIONARY}.form.title`)}>
        <input
          className={styles.Input}
          type="text"
          maxLength={64}
          value={popupForm.title}
          onChange={handleOnChange}
          name="title"
        />
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.content`)}>
        <Dext5Editor.Provider>
          <div className="flex-1 w-full">
            {updateIdx && popupForm.content && (
              <Dext5Editor.MemoizedDextEditor
                id={editorId}
                content={popupForm.content}
                className="flex-1 min-h-[500px]"
              />
            )}
            {!updateIdx && (
              <Dext5Editor.MemoizedDextEditor
                id={editorId}
                content={popupForm.content}
                className="flex-1 min-h-[500px]"
              />
            )}
          </div>
        </Dext5Editor.Provider>
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.fileUpload`)}>
        {/* TODO: 파일 업로드 추가 */}
        <input
          type="file"
          multiple
          ref={fileUploader}
          style={{ display: 'none' }}
        />

        <Button
          size="md"
          variant="outline"
          shape="rect"
          colorset="level2"
          onClick={() => fileUploader.current?.click()}
        >
          <ClipIcon />
          {t(`${POPUP_DICTIONARY}.form.fileSelect`)}
        </Button>
        {/* TODO: 파일 관리 추가 */}
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.period`)}>
        <FormAnnouncementPeroid
          popupForm={popupForm}
          setPopupForm={setPopupForm}
        />
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.target`)}>
        <FormAnnouncementTarget
          popupForm={popupForm}
          setPopupForm={setPopupForm}
        />
      </FormField>
      <FormField title={t(`${POPUP_DICTIONARY}.form.device`)}>
        <select
          className={styles.Select}
          name="device"
          id="popup-device"
          defaultValue={popupForm.device}
          onChange={handleOnChange}
        >
          {Object.entries(POPUP_DEVICES).map(([value, label]) => (
            <option key={value} value={value}>
              {t(label)}
            </option>
          ))}
        </select>
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.option`)}>
        <select
          className={styles.Select}
          name="option"
          id="popup-option"
          defaultValue={popupForm.option}
          onChange={handleOnChange}
        >
          {Object.entries(POPUP_OPTIONS).map(([value, label]) => (
            <option key={value} value={value}>
              {t(label)}
            </option>
          ))}
        </select>
      </FormField>

      <FormField title={t(`${POPUP_DICTIONARY}.form.use`)}>
        <div className="flex gap-2">
          <Switch
            id="is-use"
            checked={popupForm.isUse}
            onChange={(checked) => handleOnChangeIsUse(checked)}
          />
          <label htmlFor="is-use">
            {t(`${POPUP_DICTIONARY}.${popupForm.isUse ? 'use' : 'notUse'}`)}
          </label>
        </div>
      </FormField>
      <div className="flex justify-center gap-2">
        <Button variant="outline" shape="rect" size="md" colorset="level2">
          {t(`${POPUP_DICTIONARY}.form.preview`)}
        </Button>
        <Button
          variant="solid"
          shape="rect"
          size="md"
          colorset="level1"
          onClick={handleOnSave}
        >
          {t(`${POPUP_DICTIONARY}.form.send`)}
        </Button>
      </div>
    </>
  );
}

export default PopupAnnouncementForm;
