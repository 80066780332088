import AllowPersonalInfoEdit from './components/allow-personal-info-edit';
import DisplayItems from './components/display-items';
import CustomProfileManagement from './components/custom-profile-management';

export function FirstPage() {
  return (
    <div className="flex flex-col gap-[32px]">
      <AllowPersonalInfoEdit />
      <DisplayItems />
      <CustomProfileManagement />
    </div>
  );
}
