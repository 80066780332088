'use client';
import '@daouoffice/design/dist/components.css';
import '@daouoffice/design/dist/pages/management/preference_auth.css';
import '@daouoffice/design/dist/template/snb.css';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { TrashCanIcon } from '@daouoffice/ui/lib/icons/dop/24';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import {
  type Direction,
  SortChevron,
} from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import OrgChart from '../../../../../../OrgChart';
import { NodeProps } from '../../../../../../OrgChart/interface';
import { useContextValues } from '../authorization/manager/SubTabGroup/Context';
import { useActionsContext, useValuesContext } from '../Context';
import * as adminTableDeleteMutation from './api/adminTableDeleteMutation';
import * as adminTableInsertMutation from './api/adminTableInsertMutation';
import * as adminTableQueries from './api/adminTableQueries';
import { AdminTableType, SortType, TableRowInfo } from './index';

export interface Props {
  type: AdminTableType;
}

// TODO: Table 구현체 Tanstack Table로 변경 필요.
export function AdminTableClient({ type }: Props) {
  const { confirm } = useMessageDialog();
  const { info, warning } = useToastMessage();
  const { selectedAppCode } = useValuesContext();
  const { setCurrentMember } = useActionsContext();
  const { setEditMode } = useContextValues();
  const { t } = useTranslation('component');
  const [selectedList, setSelectedList] = useState<TableRowInfo[]>([]);
  const [orderType, setOrderType] = useState('');
  const [direction, setDirection] = useState<SortType>('ascend');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>('');
  const [size, setSize] = useState<number>(10);
  const [orgChartOpenState, setOrgChartOpenState] = useState<boolean>(false);
  const {
    data: initialData,
    error,
    refetch,
  } = useSuspenseQuery({
    queryFn: async () =>
      await adminTableQueries.fetchTableData(
        currentPage,
        type,
        selectedAppCode,
        keyword,
        { name: orderType, sortType: direction },
        size,
      ),
    queryKey: [adminTableQueries.QUERY_KEY],
  });
  const queryClient = useQueryClient();
  const addMutation = useMutation({
    mutationFn: async (id: number) => {
      return selectedAppCode === 'masterApp'
        ? await adminTableInsertMutation.postMasterDataInfo(id)
        : await adminTableInsertMutation.putTableDataInfo(selectedAppCode, [
            id,
          ]);
    },
    onSuccess: async (message: string | undefined) => {
      if (message) {
        warning(message);
      } else {
        info(
          t('globalconfig.secureManagement.adminManagement.toast.addMember'),
        );
      }
      await queryClient.invalidateQueries({
        queryKey: [adminTableQueries.QUERY_KEY],
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return selectedAppCode === 'masterApp'
        ? adminTableDeleteMutation.deleteMasterDataInfo(
            selectedList.map((member) => member.userId),
          )
        : adminTableDeleteMutation.deleteTableDataInfo(
            selectedAppCode,
            selectedList.map((member) => member.userId),
          );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [adminTableQueries.QUERY_KEY],
      });
    },
  });

  const getDirection = (direction: SortType) =>
    direction === 'ascend' ? 'asc' : 'desc';

  const onSortChangeHandler = (direction: Direction, field: string) => {
    setOrderType(field);
    setDirection(direction === 'asc' ? 'ascend' : 'descend');
  };

  const addMemberHandler = (members: NodeProps[]) => {
    if (members.length > 0) {
      members.map(
        (member) => member.userId && addMutation.mutate(member.userId),
      );
    }
    setOrgChartOpenState(false);
  };

  const deleteMemberHandler = () => {
    const handleConfirm = async () => {
      const requestConfirm = (
        <div className="w-full !px-4 flex flex-col gap-4">
          <h2 className="text-[18px] text-black font-medium">
            {t(
              'globalconfig.secureManagement.adminManagement.messageDialog.deleteAdmin.title',
            )}
          </h2>
          <span className="text-[14px] text-black font-normal whitespace-normal">
            {t(
              'globalconfig.secureManagement.adminManagement.messageDialog.deleteAdmin.contents',
            ).replace('{adminCount}', String(selectedList.length))}
          </span>
        </div>
      );

      return await confirm(requestConfirm);
    };

    handleConfirm()
      .then((res) => res && deleteMutation.mutate())
      .catch(console.error);
  };

  const memberClickHandler = (index: number) => {
    setEditMode(true);
    initialData.data[index]?.userId &&
      initialData.data[index].appList &&
      setCurrentMember({
        userId: initialData.data[index]?.userId,
        companyAppIds: initialData.data[index].appList,
      });
  };

  const columnDefs: ColumnDef<TableRowInfo>[] = [
    columnDefGenerator<TableRowInfo>(
      'name',
      t('globalconfig.secureManagement.adminManagement.table.header.name'),
      'name',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'account',
      t('globalconfig.secureManagement.adminManagement.table.header.account'),
      'account',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'position',
      t('globalconfig.secureManagement.adminManagement.table.header.position'),
      'position',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'department',
      t(
        'globalconfig.secureManagement.adminManagement.table.header.department',
      ),
      'department',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'appointment',
      t('globalconfig.secureManagement.adminManagement.table.header.duty'),
      'appointment',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    columnDefGenerator<TableRowInfo>(
      'date',
      t('globalconfig.secureManagement.adminManagement.table.header.created'),
      'date',
      orderType,
      onSortChangeHandler,
      getDirection(direction),
    ),
    {
      accessorKey: 'appList',
      id: 'appList',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.secureManagement.adminManagement.table.header.management.state',
          )}
          <SortChevron
            filterValue={'appList'}
            selectedValue={orderType}
            direction={getDirection(direction)}
            onChange={onSortChangeHandler}
          />
        </div>
      ),
      cell: (value: CellContext<TableRowInfo, unknown>) => (
        <div className="py-3 text-center">
          {value.row.original.isMaster ? (
            <span className="text-[#3A87FD] text-[14px] font-semibold leading-[14px] -tracking-[0.28px]">
              {t('globalconfig.secureManagement.adminManagement.master')}
            </span>
          ) : (
            <span className="text-[#363636] text-[14px] font-normal leading-[14px] -tracking-[0.28px]">
              {t(
                'globalconfig.secureManagement.adminManagement.table.managementState',
              ).replace(
                '{appCount}',
                String(value.row.original.appList?.length),
              )}
            </span>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetch().catch(console.error);
  }, [
    size,
    orderType,
    direction,
    currentPage,
    type,
    selectedAppCode,
    keyword,
    refetch,
  ]);

  useEffect(() => {
    type === 'management' && setCurrentMember(undefined);
  }, [type, setCurrentMember]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, size]);

  if (error) {
    return <></>;
  }

  return (
    <>
      {selectedAppCode || type === 'management' ? (
        <TablePrimitives.Root<TableRowInfo>
          columnDefs={columnDefs}
          contents={initialData.data}
          onClickRow={type === 'app' ? undefined : memberClickHandler}
          onSelectContents={setSelectedList}
          selectable={type === 'app'}
        >
          <div className="flex justify-between mb-2">
            <div className="flex flex-col">
              <TablePrimitives.SearchBar
                className="inline-block w-[240px] h-[40px]"
                keyword={keyword}
                onKeywordChange={setKeyword}
              />
              {keyword && (
                <div className="pt-3 text-[#81A9FC]">{`${initialData.data.length}${t('globalconfig.common.table.search.result')}`}</div>
              )}
            </div>
            <div className="flex items-end gap-2">
              <Dialog.Root
                modal={false}
                open={orgChartOpenState}
                onOpenChange={setOrgChartOpenState}
              >
                <Dialog.Trigger>
                  <Button className="flex items-center gap-1">
                    <PlusIcon className="size-6" />
                    <span className="font-normal leading-[150%] -tracking-[0.28px] text-[14px]">
                      {t(
                        'globalconfig.secureManagement.adminManagement.table.add.member',
                      )}
                    </span>
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content size="resizeable">
                  <OrgChart
                    type="MEMBER"
                    style={{
                      height: '680px',
                      top: '60px',
                      right: '30px',
                      left: 'auto',
                    }}
                    onConfirm={(nodes: NodeProps[]) =>
                      type === 'app' && addMemberHandler([...nodes])
                    }
                    onNodeClick={(node: NodeProps) =>
                      type === 'management' && addMemberHandler([node])
                    }
                    useMultiSelect={type === 'app' ? true : false}
                    onClose={() => setOrgChartOpenState(false)}
                  />
                </Dialog.Content>
              </Dialog.Root>
              <Button
                className="flex items-center gap-1"
                onClick={deleteMemberHandler}
              >
                <TrashCanIcon />
                <span className="font-normal leading-[150%] -tracking-[0.28px] text-[14px]">
                  {t(
                    'globalconfig.secureManagement.adminManagement.table.delete.member',
                  )}
                </span>
              </Button>
              <TablePrimitives.SizeSelector
                className="w-[52px]"
                tableSize={size}
                options={[10, 20, 30]}
                onChangeTableSize={setSize}
              />
            </div>
          </div>
          <TablePrimitives.Contents
            styleType={type === 'management' ? 'clickRow' : undefined}
          />
          <TablePrimitives.Pagination
            className="mt-[16px]"
            currentPage={currentPage ?? 0}
            totalPage={initialData?.totalPage ?? 0}
            onNextPage={setCurrentPage}
            onPreviousPage={setCurrentPage}
            onClickPage={setCurrentPage}
            onFirstPage={() => setCurrentPage(0)}
            onLastPage={() => setCurrentPage(initialData?.totalPage - 1 ?? 0)}
          />
        </TablePrimitives.Root>
      ) : (
        <div className="content_page w-full">
          <div className="section_group h-full">
            <div className="section grid place-content-center">
              {t(
                'globalconfig.secureManagement.adminManagement.table.select.app',
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminTableClient;
