import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNotification } from '../apis/delete-notification';
import * as getNotificationListQueries from '../apis/get-notification-list';
import { readNotification } from '../apis/read-notification';
import { readAllNotification } from '../apis/read-all-notification';
import { useMessageDialog } from '../../../features/message-dialog';
import { deleteAllNotification } from '../apis/delete-all-notification';
import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';

function useNotificationMutations() {
  const { confirm } = useMessageDialog();

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deleteMutation = useMutation({
    mutationFn: deleteNotification,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getNotificationListQueries.QUERY_KEY],
      });
    },
  });

  const readMutation = useMutation({
    mutationFn: readNotification,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getNotificationListQueries.QUERY_KEY],
      });
    },
  });

  const readAllMutation = useMutation({
    mutationFn: readAllNotification,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getNotificationListQueries.QUERY_KEY],
      });
    },
  });

  const deleteAllMutation = useMutation({
    mutationFn: deleteAllNotification,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getNotificationListQueries.QUERY_KEY],
      });
    },
  });

  const handleDeleteAll = async () => {
    if (await confirm(t('notification.deleteAll.confirm'))) {
      deleteAllMutation.mutate();
    }
  };

  return {
    deleteOne: (notificationId: number) =>
      deleteMutation.mutate(notificationId),
    readOne: (notificationId: number) => readMutation.mutate(notificationId),
    readAll: () => readAllMutation.mutate(),
    deleteAll: async () => await handleDeleteAll(),
  };
}

export default useNotificationMutations;
