import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProfileConfigInfo } from './type';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  QUERY_KEY,
  getProfileCardConfig,
} from './apis/get-profile-card-config';

interface ProfileManagementContextInfo {
  profileConfig: ProfileConfigInfo;
  profileId: number;
}

const ProfileManagementContext = createContext<IProfileManagementContext>({
  profileManagementContext: {
    profileConfig: {
      editedProfile: true,
      profileCardItemDisplayDtos: [
        {
          id: 0,
          name: 'string',
          custom: true,
          display: true,
        },
      ],
    },
    profileId: 0,
  },
  setProfileManagementContext: () => undefined,
});

interface IProfileManagementContext {
  profileManagementContext: ProfileManagementContextInfo;
  setProfileManagementContext: Dispatch<
    SetStateAction<ProfileManagementContextInfo>
  >;
}

export function Provider({ children }: PropsWithChildren) {
  const [profileManagementContext, setProfileManagementContext] =
    useState<ProfileManagementContextInfo>({
      profileConfig: {
        editedProfile: true,
        profileCardItemDisplayDtos: [
          {
            id: 0,
            name: 'string',
            custom: true,
            display: true,
          },
        ],
      },
      profileId: 0,
    });

  const { data: profileConfig, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getProfileCardConfig(),
  });

  useEffect(() => {
    setProfileManagementContext((prev) => ({
      ...prev,
      profileConfig,
    }));
  }, [profileConfig]);

  const values = {
    profileManagementContext,
    setProfileManagementContext,
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > BasicManagement > ProfileConfig : ',
      error,
    );
  }
  return (
    <ProfileManagementContext.Provider value={values}>
      {children}
    </ProfileManagementContext.Provider>
  );
}

export const useProfileManagementContext = () => {
  const ctx = useContext(ProfileManagementContext);
  if (ctx === null)
    throw new Error('ProfileManagement Context Provider Missing');
  return ctx;
};
