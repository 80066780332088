import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useProfileManagementContext } from '../../context';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDisplayItem } from '../../apis/update-display-item';
import * as getProfileCardConfig from '../../apis/get-profile-card-config';
import { ProfileCardItemDisplayDto } from '../../type';
import clsx from 'clsx';

export function DisplayItems() {
  const { t } = useTranslation('component');
  const {
    profileManagementContext: {
      profileConfig: { profileCardItemDisplayDtos },
    },
  } = useProfileManagementContext();
  const queryClient = useQueryClient();

  const displayItems = profileCardItemDisplayDtos.sort((a, b) => a.id - b.id);

  const updateDisplayItemMutation = useMutation({
    mutationFn: async (displayItem: ProfileCardItemDisplayDto) =>
      await updateDisplayItem(displayItem),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getProfileCardConfig.QUERY_KEY],
      });
    },
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <div className="flex flex-col flex-wrap gap-[24px] p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex flex-col justify-center gap-[8px]">
        <p className="text-[#333333] text-[22px] font-[500]">
          {t('globalconfig.basicManagement.profileManagement.displayedItems')}
        </p>
        <p className="text-[#AAA] font-[500]">
          {t(
            'globalconfig.basicManagement.profileManagement.displayedItems.description',
          )}
        </p>
      </div>
      <div className="grid grid-flow-col grid-rows-7 gap-4">
        {displayItems.map((item) => (
          <CheckBox
            key={item.id}
            id={`${item.id}`}
            label={item.name}
            defaultChecked={item.display}
            // Todo : 문의후 id로 변경 필요
            className={clsx({
              disable:
                item.name === '사진' ||
                item.name === '이름' ||
                item.name === '회사명',
            })}
            defaultDisabled={
              item.name === '사진' ||
              item.name === '이름' ||
              item.name === '회사명'
            }
            onChange={() => {
              updateDisplayItemMutation.mutate({
                ...item,
                display: !item.display,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default DisplayItems;
