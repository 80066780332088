import { QuestionCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useEffect, useState } from 'react';
import { configType } from './type';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { QUERY_KEY, getServiceMapConfig } from './apis/get-servicemap-config';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip/dop-tooltip';
import { updateServiceMapConfig } from './apis/update-servicemap-config';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useAtom } from 'jotai';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';

export function ServiceMap() {
  const { t } = useTranslation('component');
  const queryClient = useQueryClient();
  const toaster = useToastMessage();

  const [selectedConfig, setSelectedConfig] = useState<configType>('ALL');
  const [isConfigNeedsSave, setIsConfigNeedsSave] = useAtom(
    isContentNeedsSaveAtom,
  );

  const { data: serviceMapConfig, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getServiceMapConfig(),
  });

  useEffect(() => {
    setSelectedConfig(serviceMapConfig.configValue ? 'ALL' : 'PURCHASE');
  }, [serviceMapConfig]);

  const updateServiceMapConfigMutation = useMutation({
    mutationFn: async () =>
      await updateServiceMapConfig({
        configType: 'COMPANY_APP',
        configName: 'SERVICE_MAP_ALL_APP_DISPLAY',
        configValue: selectedConfig === 'ALL' ? 'true' : 'false',
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
      setIsConfigNeedsSave(false);
      toaster.info(
        t('globalconfig.secureManagement.loginSecureConfig.ip.saved'),
      );
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    setIsConfigNeedsSave(
      (selectedConfig === 'ALL') !== serviceMapConfig.configValue,
    );
  }, [selectedConfig]);

  const onClickCancle = () => {
    setSelectedConfig(serviceMapConfig.configValue ? 'ALL' : 'PURCHASE');
    setIsConfigNeedsSave(false);
  };

  const onClickSave = () => {
    updateServiceMapConfigMutation.mutate();
  };

  if (error) {
    console.error('GlobalCofig > BasicManagement > ServiceMap Error: ', error);
  }

  return (
    <div className="px-[72px] py-[40px]">
      <div className="flex flex-col gap-[24px] flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
        <div className="flex flex-col gap-2">
          <p className="text-[22px] font-medium">
            {t('globalconfig.basicManagement.serviceMap.title')}
          </p>
          <div className="flex items-center gap-[2px]">
            <p className="font-medium text-[#AAA]">
              {t('globalconfig.basicManagement.serviceMap.description')}
            </p>
            <ToolTip.Root>
              <ToolTip.Trigger>
                <QuestionCircleIcon
                  className="size-[16px] ml-[4px] text-[#AAAAAA]"
                  size={16}
                />
              </ToolTip.Trigger>
              <ToolTip.Content className="flex flex-col !items-start !max-w-full z-[1000]">
                <p>{t('globalconfig.basicManagement.serviceMap.tooltip1')}</p>
                <p>{t('globalconfig.basicManagement.serviceMap.tooltip2')}</p>
              </ToolTip.Content>
            </ToolTip.Root>
          </div>
        </div>
        <div className="flex flex-col gap-[16px]">
          <div>
            <input
              className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              id={'showAll'}
              name="serviceMapConfig"
              value={'ALL'}
              checked={selectedConfig === 'ALL'}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedConfig('ALL');
                }
              }}
            />
            <label htmlFor={'showAll'}>
              {t('globalconfig.basicManagement.serviceMap.showAll')}
            </label>
          </div>
          <div>
            <input
              className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
              type="radio"
              id={'showPurchase'}
              name="serviceMapConfig"
              value={'PURCHASE'}
              checked={selectedConfig === 'PURCHASE'}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedConfig('PURCHASE');
                }
              }}
            />
            <label htmlFor={'showPurchase'}>
              {t('globalconfig.basicManagement.serviceMap.showPurchase')}
            </label>
          </div>
        </div>
        <div className="mt-[50px] flex items-center justify-center gap-[8px]">
          <Button
            size="medium"
            shape="rect"
            variant="outline"
            colorset="level2"
            onClick={onClickCancle}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            size="medium"
            shape="rect"
            variant="solid"
            colorset="level1"
            disabled={!isConfigNeedsSave}
            onClick={onClickSave}
          >
            {t('dialog.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ServiceMap;
