import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from '@dop-ui/react/shared/ui/button';
import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip/dop-tooltip';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import {
  ArrowLeftIcon,
  QuestionMarkCircleIcon,
} from '@daouoffice/ui/lib/icons/heroicons/24/outline';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ClassRoleInfo, IPInfo } from '../types';
import { IPListManager } from '../components/ip-list-manager';
import { ClassListManager } from '../components/class-list-manager';
import { SaveConfirmDialog } from '../../../parts/components/save-confirm-dialog';
import { TitleAndDescription } from './TitleAndDescription';
import * as loginSecureConfigAPIs from './apis/loginSecureConfig';
import { isOverseasListNeedsSaveAtom } from './store/isOverseasListNeedsSaveAtom';
import {
  getDetailBlockConfig,
  QUERY_KEY_OVERSEAS_BLOCK_CONFIG,
  updateDetailBlockConfig,
} from './apis/overseasAllowedIP';
import { OverseasDetailBlockConfig } from './types';

export function OverseasLoginRestriction() {
  const { t } = useTranslation('component');
  const [ipList, setIpList] = useState<IPInfo[]>([]);
  const [classRoleInfo, setClassRoleInfo] = useState<ClassRoleInfo[]>([]);
  const [isUsingOverseasRestriction, setIsUsingOverseasRestriction] =
    useState(false);
  const [needsSave, setNeedsSave] = useAtom(isOverseasListNeedsSaveAtom);

  const {
    data: configData,
    error,
    isLoading,
  } = useQuery({
    queryKey: [loginSecureConfigAPIs.QUERY_KEY_OVERSEAS_LOGIN],
    queryFn: loginSecureConfigAPIs.getOverseasLoginConfig,
  });

  const { data: detailBlockConfig, error: blockConfigError } = useQuery({
    queryKey: [QUERY_KEY_OVERSEAS_BLOCK_CONFIG],
    queryFn: async () => await getDetailBlockConfig(),
  });

  const queryClient = useQueryClient();
  const toaster = useToastMessage();
  const stacker = useStackMethod();
  const { confirm } = useMessageDialog();

  const blockConfigMutation = useMutation({
    mutationFn: (value: OverseasDetailBlockConfig) =>
      updateDetailBlockConfig(value),
    onError: (error) => {
      toaster.warning(
        t('globalconfig.common.toastMessage.fail') + ':\n' + error.message,
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [loginSecureConfigAPIs.QUERY_KEY_OVERSEAS_LOGIN],
      });
      void queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_OVERSEAS_BLOCK_CONFIG],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setNeedsSave(false);
    },
  });

  useEffect(() => {
    console.log('configData', configData);
    setIsUsingOverseasRestriction(configData?.isBlockAbroadLogin || false);
  }, [configData]);

  useEffect(() => {
    setIpList(detailBlockConfig?.ipAccessPolicyInfoList || []);
    setClassRoleInfo(detailBlockConfig?.policyExcludeRoleInfoList || []);
  }, [detailBlockConfig]);

  useEffect(() => {
    return () => {
      setNeedsSave(false);
    };
  }, [setNeedsSave]);

  if (error) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > loginMethodConfig Error: ',
      error,
    );
  }

  if (blockConfigError) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > overseasBlockUse Error: ',
      blockConfigError,
    );
  }

  if (isLoading) return;

  const onChangeOverseasRestrictedStatus = (status: boolean) => {
    setIsUsingOverseasRestriction(status);
    if (configData && configData.isBlockAbroadLogin === status) {
      setNeedsSave(false);
    } else {
      setNeedsSave(true);
    }
  };

  const onClickCancel = () => {
    setIpList(detailBlockConfig?.ipAccessPolicyInfoList || []);
    setClassRoleInfo(detailBlockConfig?.policyExcludeRoleInfoList || []);
    setIsUsingOverseasRestriction(configData?.isBlockAbroadLogin || false);
    setNeedsSave(false);
  };

  const onClickSave = () => {
    blockConfigMutation.mutate({
      isUseBlockAbroadLogin: isUsingOverseasRestriction,
      ipAccessPolicyInfoList: ipList,
      policyExcludeRoleInfoList: classRoleInfo,
    });
  };

  const onClickBackButton = async () => {
    if (needsSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        stacker.pop();
      }
    } else {
      stacker.pop();
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
        <div className="h-[38px] mb-[48px] flex items-center">
          <Button
            className="mr-[8px]"
            size="none"
            onClick={() => void onClickBackButton()}
          >
            <ArrowLeftIcon className="size-[24px]" />
          </Button>
          <p className="text-[#333333] text-[22px] font-[500]">
            {t(
              'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.title',
            )}
          </p>
        </div>
        <TitleAndDescription
          title={t(
            'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.title',
          )}
          description={t(
            'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.innerDescription',
          )}
          tooltip={t(
            'globalconfig.secureManagement.loginSecureConfig.blockOverseasLogin.tooltip',
          )}
        >
          <Switch
            id={'globalConfig.secure.login.overseasRestriction'}
            checked={isUsingOverseasRestriction}
            onChange={onChangeOverseasRestrictedStatus}
          />
        </TitleAndDescription>
        {isUsingOverseasRestriction && (
          <div className="w-full mt-[12px] p-[24px] flex flex-wrap border border-dashed stroke-[2px] border-[#E2E2E2] rounded-[8px] gap-[24px]">
            <div className="flex flex-wrap 2xl:flex-nowrap items-start">
              <p className="text-[#111111] text-[14px] font-[400] min-w-[240px]">
                {t(
                  'globalconfig.secureManagement.loginSecureConfig.restrictIp.allowedIP',
                )}
                <ToolTip.Root>
                  <ToolTip.Trigger>
                    <QuestionMarkCircleIcon
                      className="size-[16px] ml-[4px]"
                      color="#AAAAAA"
                    />
                  </ToolTip.Trigger>
                  <ToolTip.Portal>
                    <ToolTip.Content className="z-[1000]">
                      {t(
                        'globalconfig.secureManagement.loginSecureConfig.blockOption.tooltip',
                      )}
                    </ToolTip.Content>
                  </ToolTip.Portal>
                </ToolTip.Root>
              </p>
              <IPListManager
                className="ml-[24px]"
                ipList={ipList}
                onChangeIpList={(list) => {
                  setIpList(list);
                  setNeedsSave(true);
                }}
              />
            </div>
            <div className="mt-[24px] w-full flex flex-wrap 2xl:flex-nowrap">
              <p className="text-[#111111] text-[14px] font-[400] min-w-[240px]">
                {t('globalconfig.secureManagement.classManagement.title')}
                <ToolTip.Root>
                  <ToolTip.Trigger>
                    <QuestionMarkCircleIcon
                      className="size-[16px] ml-[4px]"
                      color="#AAAAAA"
                    />
                  </ToolTip.Trigger>
                  <ToolTip.Portal>
                    <ToolTip.Content className="z-[1000]">
                      {t(
                        'globalconfig.secureManagement.classManagement.tooltip',
                      )}
                    </ToolTip.Content>
                  </ToolTip.Portal>
                </ToolTip.Root>
              </p>
              <ClassListManager
                className="ml-[24px]"
                classRoleInfo={classRoleInfo}
                onClassInfoChange={(info) => {
                  setClassRoleInfo(info);
                  setNeedsSave(true);
                }}
                accessDeviceType={'ALL'}
                accessLimitTargetType={'INCLUDE'}
              />
            </div>
          </div>
        )}
        <div className="w-full mt-[50px] flex items-center justify-center gap-[8px]">
          <Button
            size="medium"
            shape="rect"
            variant="outline"
            colorset="level1"
            onClick={onClickCancel}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            size="medium"
            shape="rect"
            variant="solid"
            colorset="level1"
            disabled={!needsSave}
            onClick={onClickSave}
          >
            {t('dialog.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}
