import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../../types';

/**
 * Sort (16x16) 아이콘
 * @param props
 * @returns
 */
export function SortIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 15 14" size={15}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02355 2.60666C7.8527 2.4358 7.57569 2.4358 7.40483 2.60666L5.0715 4.93999C4.90065 5.11084 4.90065 5.38785 5.0715 5.55871C5.24235 5.72956 5.51936 5.72956 5.69022 5.55871L7.71419 3.53473L9.73817 5.55871C9.90902 5.72956 10.186 5.72956 10.3569 5.55871C10.5277 5.38785 10.5277 5.11084 10.3569 4.93999L8.02355 2.60666ZM5.0715 8.47537L7.40483 10.8087C7.57569 10.9796 7.8527 10.9796 8.02355 10.8087L10.3569 8.47537C10.5277 8.30452 10.5277 8.02751 10.3569 7.85666C10.186 7.6858 9.90902 7.6858 9.73817 7.85666L7.71419 9.88063L5.69022 7.85666C5.51936 7.6858 5.24235 7.6858 5.0715 7.85666C4.90065 8.02751 4.90065 8.30452 5.0715 8.47537Z"
        fill="black"
      />
    </IconContainer>
  );
}

export default SortIcon;
