import { ChevronRightIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { FavoriteButton } from './FavoriteButton';
import { ReactElement } from 'react';

interface Props {
  categoryName?: ReactElement;
  menuName?: ReactElement;
}

export function Header({ categoryName, menuName }: Props) {
  return (
    <div className="w-full h-[84px] -tracking-[.08em] flex items-center pl-[75px] pb-[16px] pt-[32px] gap-[16px]">
      <span className="text-[24px] text-[#383838] font-[500]">
        {categoryName}
      </span>
      <ChevronRightIcon className="size-[24px]" />
      <span className="text-[24px] text-[#383838] font-[600]">{menuName}</span>
      <FavoriteButton />
    </div>
  );
}

export default Header;
