import { CheckInfo } from '../types';

interface AppListItem {
  companyAppId: number;
  appName: string;
  description: string;
  portalType: string;
  appManagerCount: number;
}

interface AppListAPIResponse {
  data: ResponseBodyData;
}

interface ResponseBodyData {
  organizationRoleAppList: AppListItem[]; // 조직권한 - 1
  settingAppList: AppListItem[]; // 통합설정 - 2
  employeeAppList: AppListItem[]; // 임직원 - 3
  businessAppList: AppListItem[]; // 경영업무 - 4
  isMaster: boolean; // 최고관리자 여부
  masterCount: number; // 최고관리자 수
}

export interface AuthInfoResponseData {
  organizationRoleAppList: CheckInfo[]; // 조직권한 - 1
  settingAppList: CheckInfo[]; // 통합설정 - 2
  employeeAppList: CheckInfo[]; // 임직원 - 3
  businessAppList: CheckInfo[]; // 경영업무 - 4
  isMaster: boolean; // 최고관리자 여부
  masterCount: number; // 최고관리자 수
}

export const QUERY_KEY = 'auth-info-response-data';

export async function getAuthInfoResponseData(
  appList?: number[],
): Promise<AuthInfoResponseData> {
  const response = await fetch('/api/portal/admin/security/app-manager/app');

  if (response.ok) {
    const resData = (await response.json()) as AppListAPIResponse;

    if (resData.data) {
      return {
        organizationRoleAppList: resData.data.organizationRoleAppList.map(
          (item) => adapter(item, appList),
        ),
        settingAppList: resData.data.settingAppList.map((item) =>
          adapter(item, appList),
        ),
        employeeAppList: resData.data.employeeAppList.map((item) =>
          adapter(item, appList),
        ),
        businessAppList: resData.data.businessAppList.map((item) =>
          adapter(item, appList),
        ),
        isMaster: resData.data.isMaster,
        masterCount: resData.data.masterCount,
      };
    }

    return Promise.resolve({
      organizationRoleAppList: [],
      settingAppList: [],
      employeeAppList: [],
      businessAppList: [],
      isMaster: false,
      masterCount: 0,
    } as AuthInfoResponseData);
  }

  return Promise.resolve({
    organizationRoleAppList: [],
    settingAppList: [],
    employeeAppList: [],
    businessAppList: [],
    isMaster: false,
    masterCount: 0,
  } as AuthInfoResponseData);
}

function adapter(source: AppListItem, checkList?: number[]): CheckInfo {
  return {
    id: Number(source.companyAppId),
    name: source.appName,
    isChecked: checkList?.includes(Number(source.companyAppId)) ?? false,
    sortOrder: Number(source.companyAppId),
  };
}
