import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useState } from 'react';
import * as logoThemeMutation from './apis/logo-theme-mutation';
import * as logoThemeQuery from './apis/logo-theme-query';
import { BrowserPreview } from './components/browser-preview';
import { ImageFormField } from './components/image-form-field';
import ThemePreview from './components/theme-preview';
import { CustomThemeGraphic } from './components/theme-preview/custom-theme-graphic';
import { ImageInfo, LogoThemeConfigInfo, ThemeInfo, ThemeType } from './types';
import { isHexColor } from './utils/hexColorValidator';

export function LogoThemeManagement() {
  const { t } = useTranslation('component');
  const { data, error } = useSuspenseQuery({
    queryKey: [logoThemeQuery.QUERY_KEY],
    queryFn: () => logoThemeQuery.getLogoThemeConfig(),
  });
  const saveMutation = useMutation({
    mutationKey: [logoThemeMutation.MUTATION_KEY],
    mutationFn: (req: LogoThemeConfigInfo) =>
      logoThemeMutation.putLogoThemeConfig(req),
  });

  const imageInfoHandler = (imgURL?: string) => {
    return imgURL
      ? {
          name: imgURL,
          path: imgURL,
          originFile: new File([], imgURL),
        }
      : null;
  };

  const [themeType, setThemeType] = useState<ThemeType>('dop-theme');
  const [logoImageInfo, setLogoImageInfo] = useState<ImageInfo | null>(
    data ? imageInfoHandler(data?.logoImagePath) : null,
  );
  const [loginImageInfo, setLoginImageInfo] = useState<ImageInfo | null>(
    data ? imageInfoHandler(data?.loginImagePath) : null,
  );
  const [faviconImageInfo, setFaviconImageInfo] = useState<ImageInfo | null>(
    data ? imageInfoHandler(data?.faviconImagePath) : null,
  );
  const [browserTitle, setBrowserTitle] = useState<string>(
    data ? data?.browserTitle ?? '' : '',
  );
  const [welcomeTutorialState, setWelcomTutorialState] = useState<boolean>(
    data ? data?.welcomeTutorial ?? false : false,
  );
  const [themeColor, setThemeColor] = useState<string>(
    data ? data?.themeColor ?? '#' : '#',
  );
  const [textColor, setTextColor] = useState<string>(
    data ? data?.textColor ?? '#' : '#',
  );

  const onCancelHandler = () => {
    setBrowserTitle(data ? data?.browserTitle ?? '' : '');
    setWelcomTutorialState(data ? data?.welcomeTutorial ?? false : false);
    setLogoImageInfo(data ? imageInfoHandler(data?.logoImagePath) : null);
    setLoginImageInfo(data ? imageInfoHandler(data?.loginImagePath) : null);
    setFaviconImageInfo(data ? imageInfoHandler(data?.faviconImagePath) : null);
    setThemeColor(data ? data?.themeColor ?? '' : '');
    setTextColor(data ? data?.textColor ?? '' : '');
  };

  const onSaveHandler = () => {
    const logoImageInfoStoragePath = logoImageInfo?.path ?? '';
    const loginImageInfoStoragePath = loginImageInfo?.path ?? '';
    const faviconImageInfoStoragePath = faviconImageInfo?.path ?? '';

    saveMutation.mutate({
      logoImagePath: logoImageInfoStoragePath,
      loginImagePath: loginImageInfoStoragePath,
      faviconImagePath: faviconImageInfoStoragePath,
      browserTitle,
      welcomeTutorial: welcomeTutorialState,
      themeColor,
      textColor,
    } as LogoThemeConfigInfo);
  };

  const themeList: ThemeInfo[] = [
    {
      id: 'dop-theme-green',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.green',
      ),
      themeColor: '#2FCAD7',
      textColor: '#2FCAD7',
    },
    {
      id: 'dop-theme-blue',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.blue',
      ),
      themeColor: '#6495ED',
      textColor: '#6495ED',
    },
    {
      id: 'dop-theme-purple',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.purple',
      ),
      themeColor: '#A45BCF',
      textColor: '#A45BCF',
    },
    {
      id: 'dop-theme-black',
      title: t(
        'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop.black',
      ),
      themeColor: '#4A4B4C',
      textColor: '#4A4B4C',
    },
  ];

  if (error) {
    return <></>;
  }

  return (
    <div className="flex flex-col px-[75px] pb-[75px] pt-10 gap-8">
      <div className="flex-col w-full h-full border border-[#D8D8D8] //border-[--color-border-level3] rounded-xl p-10 justify-center">
        <section id="logo-login-config">
          <h1 className="text-[20px] font-medium leading-[30px] -tracking-[0.4px] text-[#363636] mb-3">
            {t(
              'globalconfig.basicManagement.logoThemeManagement.logoConfig.title',
            )}
          </h1>
          <ImageFormField
            defaultValue={logoImageInfo}
            onChange={setLogoImageInfo}
            className="mb-6"
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.logo.title',
            )}
          />
          <ImageFormField
            defaultValue={loginImageInfo}
            onChange={setLoginImageInfo}
            className="mb-[42px]"
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.logo.loginPage',
            )}
          />
        </section>
        <div className="w-full h-0 border-solid border-t-[1px] border-[#D8D8D8] mb-6" />
        <section id="browser-config">
          <ImageFormField
            defaultValue={faviconImageInfo}
            onChange={setFaviconImageInfo}
            className="mb-6"
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.browserConfig.favicon.title',
            )}
            withTooltip={t(
              'globalconfig.basicManagement.logoThemeManagement.browserConfig.favicon.tooltip',
            )}
            isFavicon
          />
          <div className="flex items-start justify-between">
            <div className="flex gap-4 items-center mb-6">
              <span className="flex items-center gap-1 w-[200px] text-[#363636] font-medium leading-[21px] -tracking-[0.28px] text-[14px]">
                {t(
                  'globalconfig.basicManagement.logoThemeManagement.browserConfig.title',
                )}
              </span>
              <input
                type="text"
                placeholder={t(
                  'globalconfig.basicManagement.logoThemeManagement.browserConfig.title',
                )}
                className="px-3 h-10 border-solid border rounded-lg border-[#D8D8D8] w-[312px]"
                value={browserTitle}
                onChange={(e) => setBrowserTitle(e.target.value)}
              />
            </div>
            <BrowserPreview />
          </div>
        </section>
        <div className="w-full h-0 border-solid border-t-[1px] border-[#D8D8D8] mb-6" />
        <section id="theme-config">
          <h1 className="text-[20px] font-medium leading-[30px] -tracking-[0.4px] text-[#363636] mb-3">
            {t(
              'globalconfig.basicManagement.logoThemeManagement.themeConfig.title',
            )}
          </h1>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              colorset="level1"
              shape="round"
              size="md"
              className={clsx({
                '!border-[#D8D8D8]': themeType !== 'dop-theme',
                '!border-black': themeType === 'dop-theme',
              })}
              onClick={() => setThemeType('dop-theme')}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.dop',
              )}
            </Button>
            <Button
              variant="outline"
              colorset="level1"
              shape="round"
              size="md"
              className={clsx({
                '!border-[#D8D8D8]': themeType !== 'custom-theme',
                '!border-black': themeType === 'custom-theme',
              })}
              onClick={() => setThemeType('custom-theme')}
            >
              {t(
                'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.user',
              )}
            </Button>
          </div>
          {themeType === 'dop-theme' ? (
            <div className="flex mt-6 mb-6 py-6 gap-2 px-6 outline-dashed rounded-lg border-[#E5E5E5] outline-[#E5E5E5]">
              {themeList.map((themeInfo) => (
                <ThemePreview
                  key={themeInfo.id}
                  defaultChecked={themeInfo.themeColor === themeColor}
                  themeInfo={themeInfo}
                  onChange={(selectedTheme) => {
                    setTextColor(selectedTheme.textColor);
                    setThemeColor(selectedTheme.themeColor);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-between">
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-4 mt-6">
                  <span className="w-[200px] font-medium leading-[21px] -tracking-[0.28px] text-[#363636]">
                    {t(
                      'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.user.themeColor',
                    )}
                  </span>
                  <div
                    className="rounded-full size-[26px] border-solid border border-[#D8D8D8]"
                    style={{ backgroundColor: themeColor }}
                  />
                  <input
                    type="text"
                    value={themeColor}
                    onChange={(e) => {
                      isHexColor(e.target.value) &&
                        setThemeColor(e.target.value);
                    }}
                    pattern="^#[A-Fa-f0-9]{6}$"
                    className="px-3 h-10 border-solid border rounded-lg border-[#D8D8D8] w-[270px]"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <span className="w-[200px] font-medium leading-[21px] -tracking-[0.28px] text-[#363636]">
                    {t(
                      'globalconfig.basicManagement.logoThemeManagement.themeConfig.tab.user.textColor',
                    )}
                  </span>
                  <div
                    className="rounded-full size-[26px] border-solid border border-[#D8D8D8]"
                    style={{ backgroundColor: textColor }}
                  />
                  <input
                    type="text"
                    value={textColor}
                    onChange={(e) => {
                      isHexColor(e.target.value) &&
                        setTextColor(e.target.value);
                    }}
                    pattern="^#[A-Fa-f0-9]{6}$"
                    className="px-3 h-10 border-solid border rounded-lg border-[#D8D8D8] w-[270px]"
                  />
                </div>
              </div>
              <CustomThemeGraphic />
            </div>
          )}
        </section>
        {themeType === 'custom-theme' && (
          <div className="w-full h-0 border-solid border-t-[1px] border-[#D8D8D8] my-6" />
        )}
        <section
          id="tutorial-config"
          className="flex items-center justify-between mb-6"
        >
          <div className="flex flex-col gap-2">
            <h3 className="text-[16px] font-medium leading-[24px] -tracking-[0.96px] text-[#111]">
              {t(
                'globalconfig.basicManagement.logoThemeManagement.welcomeTutorial.title',
              )}
            </h3>
            {/* TODO: 튜토리얼 기능 구현 필요 */}
            <span className="text-[14px] font-normal leading-normal -tracking-[0.28px] text-[#AAA]">
              {/* {t(
                'globalconfig.basicManagement.logoThemeManagement.welcomeTutorial.contents',
              )} */}
              현재 튜토리얼 노출 기능은 동작하지 않습니다.
            </span>
          </div>
          <Switch
            id="welcomeTutorial"
            checked={welcomeTutorialState}
            onChange={setWelcomTutorialState}
          />
        </section>
        <section
          id="submit"
          className="flex items-center gap-2 justify-center py-4"
        >
          <Button
            variant="outline"
            colorset="level1"
            shape="rect"
            size="md"
            className="!border-[#C5C5C5]"
            onClick={onCancelHandler}
          >
            {t('globalconfig.basicManagement.logoThemeManagement.cancel')}
          </Button>
          <Button
            variant="solid"
            colorset="level1"
            shape="rect"
            size="md"
            onClick={onSaveHandler}
          >
            {t('globalconfig.basicManagement.logoThemeManagement.save')}
          </Button>
        </section>
      </div>
    </div>
  );
}

export default LogoThemeManagement;
